import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { Button, CircularProgress, Stack } from '@mui/material';
import {
  INITIAL_VALUES,
  VALIDATION_SCHEMA,
} from '../../../constants/kobros/collection-demo-dialog';
import InputField from './InputField';
import { REQUEST_COLLECTION_PLAN_DEMO } from '../../../graphql/mutations';
import CollectionDemoCalendar from '../dialogs/CollectionDemoCalendar';
import { createQueryString } from '../../../helpers';

function CollectionDemoForm() {
  const [
    openCollectionDemoCalendar,
    setOpenCollectionDemoCalendar,
  ] = useState(false);
  const [requestCollectionPlanDemo] = useMutation(REQUEST_COLLECTION_PLAN_DEMO);

  const [path, setPath] = useState('');

  const generatePath = (firstName, lastName, email) => {
    const queryParams = {
      name: `${firstName} ${lastName}`.trim(),
      email,
    };
    return `?${createQueryString(queryParams)}`;
  };

  return (
    <>
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={(
          { firstName, lastName, email, phoneNumber },
          { setSubmitting, resetForm },
        ) => {
          requestCollectionPlanDemo({
            variables: {
              name: `${firstName} ${lastName}`,
              email: email.toLowerCase(),
              phoneNumber,
            },
            onCompleted: () => {
              setSubmitting(false);
              setPath(generatePath(firstName, lastName, email));
              resetForm();
              setOpenCollectionDemoCalendar(true);
            },
          });
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Stack
              flexDirection="column"
              alignItems="center"
              maxWidth={400}
              m="auto"
              spacing={1}
            >
              <InputField
                id="demo-kobros-name"
                name="firstName"
                label="Nombre"
                color="secondary"
                sx={{ width: { xs: 150, smartphone: 250, md: 400 } }}
              />
              <InputField
                id="demo-kobros-last-name"
                name="lastName"
                label="Apellido"
                color="secondary"
                sx={{ width: { xs: 150, smartphone: 250, md: 400 } }}
              />
              <InputField
                id="demo-kobros-phone-number"
                name="phoneNumber"
                label="Número de teléfono"
                color="secondary"
                startAdornment="+56"
                sx={{ width: { xs: 150, smartphone: 250, md: 400 } }}
              />
              <InputField
                id="demo-kobros-email"
                name="email"
                label="Email"
                color="secondary"
                sx={{ width: { xs: 150, smartphone: 250, md: 400 } }}
              />
              <Button
                id="request-demo-kobros"
                type="submit"
                variant="outlined"
                color="secondary"
                disabled={isSubmitting}
                sx={{
                  fontSize: { xs: 12, md: 14 },
                  width: { xs: 140, smartphone: 250 },
                  height: { xs: 30, md: 41 },
                  borderRadius: 20,
                  mt: 1,
                }}
              >
                {isSubmitting ? (
                  <CircularProgress size="small" color="secondary" />
                ) : (
                  'Solicitar información'
                )}
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
      <CollectionDemoCalendar
        open={openCollectionDemoCalendar}
        onClose={() => setOpenCollectionDemoCalendar(false)}
        path={path}
      />
    </>
  );
}

export default CollectionDemoForm;
