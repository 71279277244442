import { FactCheck, Email, StackedBarChart, Handshake, Badge, BorderColor, People, Warning, CalendarMonth } from '@mui/icons-material';

const SOLUTIONS_CARDS = [
  {
    id: 'collection-portfolio',
    title: 'Ordena y gestiona tu cartera de cobranza',
    icon: FactCheck,
    reverseColors: true,
    appearDelay: 0.1,
  },
  {
    id: 'mails',
    title: 'Automatiza el envío de mails',
    icon: Email,
    reverseColors: true,
    appearDelay: 0.1,
  },
  {
    id: 'collection-priority',
    title: 'Cobra por prioridades',
    icon: StackedBarChart,
    reverseColors: true,
    appearDelay: 0.1,
  },
  {
    id: 'supplier portal',
    title: 'Portal de proveedores',
    icon: Handshake,

    appearDelay: 0.5,
  },
  {
    id: 'future-clients',
    title: 'Analiza tus potenciales clientes',
    icon: Badge,
    appearDelay: 0.5,
  },
  {
    id: 'collection-action',
    title: 'Deja registro de tus gestiones',
    icon: People,
    appearDelay: 0.5,
  },
  {
    id: 'permissions-roles',
    title: 'Roles y perfiles',
    icon: BorderColor,
    appearDelay: 1,
  },
  {
    id: 'alerts',
    title: 'Obtén alertas',
    icon: Warning,
    appearDelay: 1,
  },
  {
    id: 'whatsapp-notifications',
    title: 'Ve los plazos de pago promedio',
    icon: CalendarMonth,
    appearDelay: 1,
  },
];

const SOLUTIONS_TITLE = [
  { id: 1, text: 'Features de ', color: 'text.main', fontSize: 90 },
  { id: 2, text: ' kobros', color: 'secondary.main' },

];

export { SOLUTIONS_CARDS, SOLUTIONS_TITLE };
