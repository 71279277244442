import React from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { Buro } from '../../../assets/home';
import { buroInfoParagraphs } from '../../../constants/legal';

function BuroHelpComponent() {
  const theme = useTheme();
  return (
    <Stack direction="column" textAlign="center" width="100%" alignItems="center" spacing={2}>
      <Typography variant="subtitle1" sx={{ color: theme.palette.primary.main, my: 4 }}>
        ¿Qué es el Buró de Entidades financieras?
      </Typography>
      <Box
        component="img"
        src={Buro}
        alt=""
        sx={{
          maxHeight: 80,
          maxWidth: 'auto',
          objectFit: 'contain',
        }}
      />
      <Stack direction="column" textAlign="left" spacing={2}>
        {
        buroInfoParagraphs.map((paragraph) => (
          <Typography variant="subtitile4">
            {paragraph}
          </Typography>
        ))
      }
      </Stack>
    </Stack>
  );
}

export default BuroHelpComponent;
