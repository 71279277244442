import React from 'react';
import { getCdnUrl } from '../../helpers';
import { useIsMobile } from '../../hooks';
import SectionPhoto from '../Utils/SectionPhoto';

const text = 'La vida es más fácil con conexiones más empáticas';

function HandPhoto() {
  const isMobile = useIsMobile('sm');
  const src = isMobile ? '/images/hands_coffe_mobile.jpeg' : '/images/hands_coffe.jpeg';
  return (
    <SectionPhoto
      photo={getCdnUrl(src)}
      text={text}
    />
  );
}

export default HandPhoto;
