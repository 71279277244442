import React from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useContactInformation from '../../../../hooks/useContactInformation';
import ContactText from '../../../Utils/text/ContactText';
import { useIsMobile, useSocialMediaLinks } from '../../../../hooks';
import SocialMediaStack from '../SocialMediaStack';
import { BuroLogo } from '../../../Utils/logo';
import { contactDisclaimer, fintechOperationsDisclaimer, governmentResponsibilityDisclaimer, personalDataDisclaimer } from '../../../../constants/footer/mexico-footer-tyc-text';
import FingoLogo from '../../../Utils/logo/FingoLogo';
import { PrimaryLink } from '../../../Utils/link';
import legalLinks from '../../../../constants/footer/mexico-legal-links';

function FooterMexico() {
  const { mail, phoneNumber, address } = useContactInformation();
  const { mapsLink } = useSocialMediaLinks();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const contactTextArray = [
    {
      title: '¡Contáctanos!',
      textArray: [
        mail,
        phoneNumber,
      ],
    },
    {
      title: 'Dirección',
      textArray: [
        address,
        <PrimaryLink
          href={mapsLink}
          rel="noopener noreferrer"
          target="_blank"
        >
          Ver en Maps
        </PrimaryLink>,
      ],
    },
  ];

  return (
    <Stack
      direction="column"
      py={5}
      px={isMobile ? 5 : 12}
      sx={{ backgroundColor: theme.palette.background.light }}
      spacing={6}
    >
      <Stack
        direction={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
        spacing={isMobile ? 4 : 2}
        alignItems={isMobile ? 'center' : 'left'}
      >
        <Stack direction={isMobile ? 'row' : 'column'} spacing={4}>
          {contactTextArray.map((contact) => (
            <ContactText
              title={contact.title}
              textArray={contact.textArray}
              key={contact.title}
              direction="column"
              alignItems="left"
              spacing={1}
              maxWidth={200}
            />
          ))}
        </Stack>
        <Stack direction={isMobile ? 'row' : 'column'} justifyContent={isMobile ? 'center' : null} spacing={isMobile ? 4 : 1}>
          <BuroLogo />
          <Stack direction="column" spacing={1}>
            {legalLinks.map((link) => (
              <Typography variant="subtitle4" key={link.text}>
                <PrimaryLink
                  href={link.href}
                  rel="noopener noreferrer"
                  target={link.href ? '_blank' : null}
                  download={link.isDownload ? link.text : null}
                  onClick={link.redirect ? () => { navigate(`${link.redirect}`); } : null}
                  component={link.redirect ? 'button' : 'a'}
                  textAlign="left"
                >
                  {link.text}
                </PrimaryLink>
              </Typography>
            ))}
          </Stack>
        </Stack>
        <Stack direction="column" spacing={2} maxWidth={isMobile ? 550 : 400} textAlign={isMobile ? 'center' : 'left'}>
          <Typography variant="subtitle4">
            {personalDataDisclaimer}
          </Typography>
          <Typography variant="subtitle4">
            {contactDisclaimer}
          </Typography>
        </Stack>
        <Stack direction="column" justifyContent="space-between" alignItems="center">
          <FingoLogo sx={{ maxHeight: 60 }} />
          <SocialMediaStack />
        </Stack>
      </Stack>
      <Stack direction="column" spacing={2}>
        <Typography variant="subtitle4">
          {governmentResponsibilityDisclaimer}
        </Typography>
        <Typography variant="subtitle4">
          {fintechOperationsDisclaimer}
        </Typography>
      </Stack>
    </Stack>
  );
}

export default FooterMexico;
