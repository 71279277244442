const FAQs = [
  {
    title: '¿Qué es Fingo y qué hace?',
    paragraphs: [
      `
        Somos una nueva financiera 100% digital y entregamos
        soluciones de financiamiento a las empresas a través
        del anticipo de facturas y órdenes de compra.
        Con Fingo podrás financiar tus facturas electrónicas,
        las cuales te adelantaremos inmediatamente, mientras nosotros nos
        encargamos de su cobro por ti.
      `,
      `
        En nuestra plataforma podrás operar y obtener financiamiento de
        forma rápida, con las mejores condiciones comerciales y flexibilidad
        en las fechas de vencimiento.
      `,
    ],
  },
  {
    title: '¿Cómo operar con Fingo?',
    paragraphs: [
      `
        Para utilizar nuestro servicio, solo tienes que registrarte en nuestra plataforma y podrás:
      `,
      `
        1) Ver las ofertas que tenemos para tus facturas de manera automática.
      `,
      `
        2) Cotizar gratis tus tasas finales.
      `,
      `
        3) Cedernos tus facturas directamente desde la plataforma.
      `,
      `
        4) Revisar el estado de tus cesiones y giros en tiempo real.
      `,
      `
        5) Ver el estado de cobranza de tus facturas en tiempo real.
      `,
      `
        6) Saber cuáles son tus excedentes disponibles y pedir el giro de ellos directamente desde la plataforma.
      `,
      `
        El registro en nuestra plataforma es gratis.
      `,
    ],
  },
  {
    title: '¿Cuáles son los costos de operar?',
    paragraphs: [
      `
        Financiar tus facturas con Fingo tiene un bajo costo y está compuesto por:
      `,
      `
        1. Una diferencia de precio (tasa) que se calcula según tu nivel de riesgo y el de tu
        cliente.
      `,
      `
        2. La comisión que cobramos. No te preocupes, es muy baja.
      `,
      `
        Además, normalmente debemos aplicar retenciones por si tu cliente se demora en pagar y
        la factura cae en mora. En el caso que la retención no se utilice, se transformará en
        excedente y podrás solicitar que te la depositemos el mismo día directamente a tu cuenta
        bancaria.
      `,
      `
        El excedente retenido lo podrás pedir una vez que tu cliente haya pagado y se salden
        todos los intereses por mora y otras deudas que puedas tener.
      `,
    ],
  },
  {
    title: '¿Qué documentos necesito para operar con Fingo?',
    paragraphs: [
      `
        Para comenzar a operar con nosotros, te pediremos tus credenciales del certificado del
        Servicio de Impuestos Internos para que podamos tener acceso a tus facturas en línea.
      `,
      `
        Sólo tendrás que hacerlo una vez, ya que quedará registrado en tu cuenta de Fingo.
      `,
      `
        Toda la información que nos entregues de tus credenciales quedará encriptada y segura.
      `,
    ],
  },
  {
    title: '¿Puedo cambiar la fecha de pago del cliente una vez que ya acepté la cesión de la factura?',
    paragraphs: [
      `
        No se puede cambiar la fecha de pago.
      `,
      `
        No debiéramos, solo en una excepción donde se muestre que hubo error. Sino se presta
        para que no paguen nunca interés por mora.
      `,
    ],
  },
  {
    title: '¿Cómo se calcula la comisión?',
    paragraphs: [
      `
        La comisión que te cobramos es variable: un 0,2%, con un mínimo de $20.000 para
        operaciones menores a $5.000.000 y $40.000 para operaciones mayores a $5.000.000,
        con un tope de $100.000 + iva.
      `,
    ],
  },
  {
    title: '¿Me van a notificar si es que mi cliente les paga? Y en caso de atraso, ¿cómo voy a saber?',
    paragraphs: [
      `
        Sí, te notificaremos mediante correo electrónico si tu cliente paga. Además, podrás revisar
        esta información en la página de operaciones de nuestra plataforma.
      `,
      `
        Si tu cliente se atrasa en el pago, también te notificaremos mediante correo electrónico y
        en la plataforma Fingo.
      `,
    ],
  },
];

export default FAQs;
