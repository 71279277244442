import React from 'react';
import { Box, Chip, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { SOLUTIONS_CARDS, SOLUTIONS_TITLE } from '../../constants/kobros';
import CompositeTypography from '../Utils/typography/CompositeTypography';
import { PCKobros } from '../../assets/kobros';

function FeatureItem({ Icon, label, reverseColors }) {
  const bgcolor = reverseColors ? 'secondary.main' : 'white';
  const frontcolor = reverseColors ? 'white' : 'secondary.main';
  return (
    <Box
      height="140px"
      width="200px"
      bgcolor={bgcolor}
      borderRadius={5}
      textAlign="center"
      alignContent="center"
      margin="auto"
    >
      <Icon sx={{ color: frontcolor, fontSize: '36px' }} />
      <Typography width="90%" margin="auto" color={frontcolor}>
        {label}
      </Typography>
    </Box>
  );
}
function Solutions() {
  return (
    <div
      id="home-features"
    >
      <CompositeTypography
        textAlign="center"
        mt={8}
        textParts={SOLUTIONS_TITLE}
      />
      <Chip
        sx={{
          my: 2,
          left: '45%',
          width: '10%',
          height: '12px',
          position: 'relative',
          color: 'background.light',
        }}
      />
      <Grid container>
        <Grid item lg={6} md={12} alignSelf="center">
          <Box
            component="img"
            height={480}
            alt="PCKobros"
            src={PCKobros}
          />
        </Grid>
        <Grid item container lg={6} md={12}>
          {SOLUTIONS_CARDS.map(({ id, title, icon: CustomIcon, appearDelay, reverseColors }) => (
            <Grid item lg={4} md={6} xs={6} my={1} justifyContent="space-around">
              <FeatureItem
                id={id}
                Icon={CustomIcon}
                appearDelay={appearDelay}
                label={title}
                reverseColors={reverseColors}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
}

FeatureItem.propTypes = {
  Icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  reverseColors: PropTypes.bool.isRequired,
};

export default Solutions;
