import { useGetCountry } from '../helpers';

const useContactInformation = () => {
  const country = useGetCountry();
  if (country === 'Mexico') {
    return {
      mail: 'contacto@fingo.com.mx',
      phoneNumber: '+52 56 4861 2937',
      address: 'Varsovia 36, oficina 504. Colonia Juarez, Ciudad de Mexico',
      // Check if its 504 or 604
      floor: '5',
    };
  }
  return {
    mail: 'contacto@fingo.cl',
    phoneNumber: '+56 9 6246 4446',
    address: 'Av. Apoquindo 4660, Las Condes',
    floor: 'Piso 8',
  };
};

export default useContactInformation;
