import React from 'react';
import SectionPhoto from '../../Utils/SectionPhoto';
import { useIsMobile } from '../../../hooks';
import { getCdnUrl } from '../../../helpers';

const text = 'La vida es más fácil trabajando en equipo';

function WorkerPhoto() {
  const isMobile = useIsMobile('sm');
  const src = isMobile ? '/images/workers_photo_mobile.jpg' : '/images/workers_photo.jpg';
  return (
    <SectionPhoto
      photo={getCdnUrl(src)}
      text={text}
    />
  );
}

export default WorkerPhoto;
