const useGetCountry = () => {
  // Extract the TLD from the hostname
  const topLevelDomain = window.location.hostname.split('.').pop();
  switch (topLevelDomain) {
    case 'cl':
      return 'Chile';
    case 'mx':
      return 'Mexico';
    default:
      return 'Chile';
  }
};

export default useGetCountry;
