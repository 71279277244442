import React from 'react';
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { IntercomProvider } from 'react-use-intercom';
import Navbar from './components/Layout/Navbar/Navbar';
import Footer from './components/Layout/Footer/Footer';
import theme from './theme';
import Home from './components/Home/Home';
import Team from './components/Team/Team';
import HelpCenter from './components/HelpCenter/HelpCenter';
import Products from './components/Products/ProductView/Products';
import UtmCampaignTracker from './components/Utils/UtmCampaignTracker';
import HotjarProvider from './components/Utils/HotjarProvider';
import ScrollToTop from './components/Utils/ScrollToTop';
import PixelProvider from './components/Utils/PixelProvider';
import Platform from './components/Platform/Platform';
import TrackerProvider from './components/Utils/TrackerProvider';
import Kobros from './components/Kobros/Kobros';
import ExistingAccountDialog from './components/Kobros/dialogs/ExistingAccountDialog';
import CollectionRegisterDialog from './components/Kobros/dialogs/CollectionRegisterDialog';
import HelpComplianceInfo from './components/HelpCenter/HelpComplianceInfo';
import Terms from './components/Terms/Terms';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <IntercomProvider
        appId={process.env.REACT_APP_INTERCOM_APP_ID}
        autoBoot
        shouldInitialize
      >
        <PixelProvider>
          <HotjarProvider>
            <Router>
              <TrackerProvider>
                <UtmCampaignTracker>
                  <ScrollToTop />
                  <ExistingAccountDialog />
                  <CollectionRegisterDialog />
                  <Navbar />
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/products" element={<Products />} />
                    <Route path="/platform" element={<Platform />} />
                    <Route path="/team" element={<Team />} />
                    <Route path="/help/:type" element={<HelpComplianceInfo />} />
                    <Route path="/help" element={<HelpCenter />} />
                    <Route path="/kobros" element={<Kobros />} />
                    <Route path="/terms" element={<Terms />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                  </Routes>
                  <Footer />
                </UtmCampaignTracker>
              </TrackerProvider>
            </Router>
          </HotjarProvider>
        </PixelProvider>
      </IntercomProvider>
    </ThemeProvider>
  );
}

export default App;
