export const TITLE_PARTS = [
  { id: 1, text: 'Explora', color: 'text.main' },
  { id: 2, text: ' koBros: ', color: 'secondary.main' }];
export const TITLE_PARTS2 = [
  { id: 3, text: 'la nueva', color: 'text.main' },
  { id: 4, text: ' herramienta de cobranza', color: 'secondary.main' },
  { id: 5, text: ' de Fingo', color: 'text.main' },
];
export const BODY_PARTS = [
  { id: 1, text: 'koBros', color: 'text.main', fontWeight: 'bold', fontSize: 18 },
  {
    id: 2,
    text: ' es una herramienta de Fingo que te ayuda a ordenar y gestionar tu cobranza. Podrás tomar ',
    color: 'text.main',
    fontSize: 18,
  },
  { id: 5, text: 'mejores decisiones con tus clientes', fontWeight: 'bold', fontSize: 18 },
  { id: 6, text: ', recordar y cobrar de forma ', fontSize: 18 },
  { id: 7, text: 'eficiente', fontWeight: 'bold', fontSize: 18 },
  { id: 8, text: ' y contar con los ', fontSize: 18 },
  { id: 9, text: 'flujos', fontWeight: 'bold', fontSize: 18 },
  { id: 10, text: ' cuando los necesitas.', fontSize: 18 },
];

export const MR_KOBROS_ADVICE = [
  { id: 1, text: 'Ordena y gestiona', color: 'secondary.main', fontSize: '32px !important' },
  { id: 2, text: ' tu cartera de cobranza, ', color: 'text.main', fontSize: '32px !important' },
  { id: 3, text: ' envía correos ', color: 'secondary.main', fontSize: '32px !important' },
  { id: 4, text: ' a tus deudores desde la plataforma con un solo click, y mucho más... ', fontSize: '32px !important' },

];

export const EXISTING_ACCOUNT_DIALOG_BODY_PARTS = [
  { id: 1, text: 'koBros', color: 'secondary' },
  {
    id: 2,
    text: ' es la herramienta de cobranza de Fingo, por lo que podrás ingresar a koBros con tu cuenta de Fingo',
    color: 'text',
  },
];
