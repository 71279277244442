import React, { useRef, useState } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useIsFirstTimeVisible } from '../../hooks';

function EaseInAndOut({ children }) {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);
  const sx = useIsFirstTimeVisible({ ref, isVisible, setIsVisible });

  return (
    <Grid
      sx={sx}
      ref={ref}
    >
      {children}
    </Grid>
  );
}

EaseInAndOut.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EaseInAndOut;
