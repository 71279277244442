import React from 'react';
import FingoBenefitsDesktop from './FingoBenefitsDesktop';
import FingoBenefitsMobile from './FingoBenefitsMobile';
import { useIsMobile } from '../../../hooks';
import getPlatformUrlByCountry from '../../../helpers/getPlatformUrlByCountry';
import handleUtmRedirect from '../../../helpers/handleUtmRedirect';
import useGetCountry from '../../../helpers/useGetCountry';

const financingBenefits = [
  'Proceso 100% online, con firma de contrato digital',
  'Atención personalizada, con ejecutivos a disposición en todo momento',
  'Control de todas las facturas de venta en un lugar centralizado y ordenado',
  'Otras opciones de financiamiento según las necesidades de cada cliente',
];

const supplierPortalBenefits = [
  'Manejo de información y comunicación con proveedores',
  'Financia tus cuentas por cobrar de manera digital y rápida',
  'Opción de postergar el pago de la factura vía un contrato de confirming a tasas previamente acordadas',
  'Fidelización de proveedores debido a beneficios que obtienen por convenios',
];

const financingBenefitsMobile = [
  'Proceso 100% online',
  'Atención personalizada',
  'Control de todas las facturas de venta en un lugar centralizado y ordenado',
  'Otras opciones de financiamiento según las necesidades de cada cliente',
];

const supplierPortalBenefitsMobile = [
  'Comunicación con proveedores',
  'Financia tus cuentas por cobrar',
  'Postergar pago de la factura vía contrato de confirming a tasas previamente acordadas',
  'Fidelización de proveedores debido a beneficios que obtienen por convenios',
];

const benefitLists = [
  { id: 'financing', title: 'Beneficios financiamiento', list: financingBenefits },
  { id: 'supplier-portal', title: 'Beneficios portal proveedores', list: supplierPortalBenefits },
];

const benefitListsMobile = [
  { id: 'financing', title: 'Beneficios financiamiento', list: financingBenefitsMobile },
  { id: 'supplier-portal', title: 'Beneficios portal proveedores', list: supplierPortalBenefitsMobile },
];

const benefitsByCountry = {
  Chile: ['financing', 'supplier-portal'],
  Mexico: ['financing'],
};

const getBenefitsByCountry = (country, benefitsList) => {
  const countryBenefits = benefitsByCountry[country] || [];
  return benefitsList.filter((benefit) => countryBenefits.includes(benefit.id));
};

function FingoBenefits() {
  const isMobile = useIsMobile();
  const platformUrl = getPlatformUrlByCountry();
  const country = useGetCountry();
  const handleOnClick = () => {
    handleUtmRedirect(`${platformUrl}/register`);
  };
  const filteredBenefits = isMobile ? getBenefitsByCountry(country, benefitListsMobile)
    : getBenefitsByCountry(country, benefitLists);
  const BenefitsComponent = isMobile ? FingoBenefitsMobile : FingoBenefitsDesktop;

  return (
    <BenefitsComponent
      benefitLists={filteredBenefits}
      onClick={handleOnClick}
    />
  );
}

export default FingoBenefits;
