import { Stack, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

function ContactText({ title, textArray, ...props }) {
  return (
    <Stack alignItems="center" {...props}>
      <Typography variant="subtitle3">{title}</Typography>
      {textArray.map((text) => (
        <Typography variant="subtitle4">{text}</Typography>
      ))}
    </Stack>
  );
}

ContactText.propTypes = {
  title: PropTypes.string.isRequired,
  textArray: PropTypes.string.isRequired,
  // alignment: PropTypes.oneOf(['vertically', 'horizontally']),
};

export default ContactText;
