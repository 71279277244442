import {
  CalendarMonthOutlined,
  CheckCircleOutlined,
  ShoppingCartOutlined,
  TextSnippetOutlined,
} from '@mui/icons-material';
import React from 'react';

const styles = {
  icon: {
    fontSize: '3.5rem',
    '@media (max-width: 600px)': {
      fontSize: '2.5rem',
    },
  },
};

const productsArray = [
  {
    backTitle: 'Factoring',
    title: 'Anticipa tus facturas',
    text: 'Es una alternativa de financiamiento que consiste en el traspaso de facturas y de su cobranza futura, a cambio del pago inmediato de esa factura.',
    icon: <TextSnippetOutlined sx={styles.icon} />,
    url: '/mypurchases',
  },
  {
    backTitle: 'Ordering',
    title: 'Anticipa tus órdenes de compra',
    text: 'Usa tus órdenes de compra como respaldo para poder solicitar financiamiento. Luego págalas con transferencia o con tus facturas.',
    icon: <ShoppingCartOutlined sx={styles.icon} />,
    url: '/mysales',
  },
  {
    backTitle: 'Confirming',
    title: 'Confirming',
    text: 'Extiende el plazo de las cuentas por pagar a tus proveedores. Nosotros nos encargamos de la gestión y pago de facturas a los distintos proveedores por la ejecución de sus trabajos.',
    icon: <CheckCircleOutlined sx={styles.icon} />,
    url: '/financing',
  },
  {
    backTitle: 'Supplier factoring',
    title: 'Supplier factoring',
    text: 'Entregamos financiamiento directo del 100% de tu factura y sin costos por mora.',
    icon: <CalendarMonthOutlined sx={styles.icon} />,
    url: '/analytics',
  },
];

export default productsArray;
