import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useSocialMediaLinks } from '../../../hooks';
import linkedin from '../../../assets/socialMedia/images/linkedin.png';
import instagram from '../../../assets/socialMedia/images/instagram.png';
import whatsapp from '../../../assets/socialMedia/images/whatsapp.png';

const styles = {
  footerContainer: {
    bottom: 0,
    position: 'relative',
    height: '27rem',
    width: '100%',
    display: 'table',
    backgroundColor: '#F8F8F8',
    color: 'text.primary',
    padding: '4rem',
  },
  footerTitle: {},
  spacingGrid: {
    minHeight: '3.9rem',
    justifyContent: 'space-evenly',
  },
  href: {
    color: '#323232',
    textDecoration: 'none',
  },
  logo: {
    width: 130,
  },
  socialMedia: {
    width: 20,
    marginTop: '21%',
  },
};

function SocialMediaStack() {
  const { linkedinLink, instagramLink, whatsappLink } = useSocialMediaLinks();

  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="subtitle3">¡Síguenos!</Typography>
      <Stack direction="row" spacing={1}>
        <a href={linkedinLink} rel="noopener noreferrer" target="_blank">
          <img src={linkedin} alt="logo" style={styles.socialMedia} />
        </a>
        <a href={instagramLink} rel="noopener noreferrer" target="_blank">
          <img src={instagram} alt="logo" style={styles.socialMedia} />
        </a>
        <a href={whatsappLink} rel="noopener noreferrer" target="_blank">
          <img src={whatsapp} alt="logo" style={styles.socialMedia} />
        </a>
      </Stack>
    </Stack>
  );
}

export default SocialMediaStack;
