const buroInfoParagraphs = [
  `
    Es una herramienta de consulta y difusión con la que podrás conocer los productos que ofrecen 
    las entidades financieras, sus comisiones y tasas, las reclamaciones de los usuarios, las 
    prácticas no sanas en que incurren, las sanciones administrativas que les han impuesto, las 
    cláusulas abusivas de sus contratos y otra información que resulte relevante para informarte 
    sobre su desempeño.
    `,
  `
    Con el Buró de Entidades Financieras, se logrará saber quién es quién en bancos, seguros, 
    sociedades financieras de objeto múltiple, cajas de ahorro, afores, entre otras entidades.
    `,
  `
    Con ello, podrás comparar y evaluar a las entidades financieras, sus productos y servicios y 
    tendrás mayores elementos para elegir lo que más te convenga.
    `,
  `
    Esta información te será útil para elegir un producto financiero y también para conocer y usar 
    mejor los que ya tienes.
    `,
  `
    Este Buró de Entidades Financieras, es una herramienta que puede contribuir al crecimiento 
    económico del país, al promover la competencia entre las instituciones financieras; que 
    impulsará la transparencia al revelar información a los usuarios sobre el desempeño de éstas y 
    los productos que ofrecen y que va a facilitar un manejo responsable de los productos y 
    servicios financieros al conocer a detalle sus características.
    `,
  `
    Lo anterior, podrá derivar en un mayor bienestar social, porque al conjuntar en un solo 
    espacio tan diversa información del sistema financiero, el usuario tendrá más elementos para 
    optimizar su presupuesto, para mejorar sus finanzas personales, para utilizar correctamente 
    los créditos que fortalecerán su economía y obtener los seguros que la protejan, entre otros 
    aspectos.
    `,
  `
    El Buró de Entidades Financieras contiene información de FINGO TECH Capital, S.A.P.I. de C.V
    ., SOFOM, E.N.R. sobre nuestro desempeño frente a los Usuarios, por la prestación de productos 
    y servicios.
    `,
  `
    Fuente: https://www.buro.gob.mx/general_gob.php?id_sector=69&id_periodo=39#
    `,
];

export default buroInfoParagraphs;
