import React from 'react';
import { AppBar, Toolbar, Grid, Button, Typography, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';
import handleUtmRedirect from '../../../helpers/handleUtmRedirect';
import DESKTOP_NAVBAR_HEIGHT from '../../../constants/desktop-navbar';

const styles = {
  appBar: {
    backgroundColor: 'white',
    zIndex: (theme) => theme.zIndex.drawer + 1,
    boxShadow: '1px 8px 8px rgba(0, 0, 0, 0.05)',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 2rem',
    backgroundColor: 'white',
  },
};

function DesktopNavbar({ buttons, pages, Logo }) {
  const {
    location: { pathname },
  } = window;
  const navigate = useNavigate();
  return (
    <AppBar sx={styles.appBar} position="sticky">
      <Toolbar sx={styles.toolbar}>
        <Stack direction="row">
          <Logo />
          <Grid
            alignItems="left"
            justifyContent="flex-start"
            xs={7}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            {pages.map(({ url, title, scroll }) => (
              scroll ? (
                <Link
                  to={url}
                  offset={-DESKTOP_NAVBAR_HEIGHT}
                  smooth
                  duration={1000}
                >
                  <Button id={`navbar-${url}-desktop`} variant="navlink">
                    <Typography variant="subtitle5" color="text.primary">
                      {title}
                    </Typography>
                  </Button>
                </Link>
              ) : (
                <Button
                  id={`navbar-${url}-desktop`}
                  key={title}
                  color={pathname === url ? 'primary' : 'text'}
                  onClick={() => (typeof url === 'string' ? navigate(url) : url())}
                >
                  {title}
                </Button>
              )
            ))}
          </Grid>
        </Stack>
        <Grid
          item
          justifyContent="flex-end"
          sx={{ display: 'flex', alignItems: 'center', justifySelf: 'flex-end' }}
        >
          {buttons.map(({ title, id, url, queryParams, ...rest }) => (
            <Grid item key={title}>
              <Button
                id={`navbar-${id}-desktop`}
                sx={{ marginLeft: '1rem' }}
                onClick={() => (typeof url === 'string' ? handleUtmRedirect(url, queryParams) : url())}
                {...rest}
              >
                {title}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

DesktopNavbar.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  pages: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  Logo: PropTypes.node.isRequired,
};

export default DesktopNavbar;
