import Cookies from 'universal-cookie';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

function UtmCampaignTracker({ children }) {
  const cookies = new Cookies();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const utmCampaign = searchParams.get('utm_campaign') || '';
    cookies.set('utm_campaign', utmCampaign, { path: '/' });
  }, []);
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
}

UtmCampaignTracker.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UtmCampaignTracker;
