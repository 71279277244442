/* eslint-disable import/prefer-default-export */
import React from 'react';
import { SvgIcon } from '@mui/material';
import PropTypes from 'prop-types';
import { ReactComponent as WhatsappIconImage } from './whatsapp.svg';

function WhatsappIcon(props) {
  const { sx } = props;
  return (
    <SvgIcon {...props} alt="whatsapp-icon">
      <WhatsappIconImage fill={sx.color} />
    </SvgIcon>
  );
}

WhatsappIcon.propTypes = {
  sx: PropTypes.shape({
    color: PropTypes.string,
  }),
};

WhatsappIcon.defaultProps = {
  sx: {
    color: 'black',
  },
};

export { WhatsappIcon };
