import React, { useRef, useState } from 'react';
import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import PaperPlane from '../../assets/home/images/paper_plane.png';
import FingoBackground from '../../assets/brand/images/fingo_background.png';
import TwistedDottedLine from '../../assets/home/images/twisted_dotted_line.png';
import { StepCard } from '../Utils/cards';
import { useIsFirstTimeVisible, useIsMobile } from '../../hooks';
import { FloatingComponent } from '../Utils/animations';
import useGetCountry from '../../helpers/useGetCountry';

const fullSteps = [
  { number: 1, text: 'Regístrate en nuestra plataforma', appearDelay: 0.1 },
  { number: 2, text: 'Revisa tus compras, ventas y opciones de financiamiento', appearDelay: 0.4 },
  { number: 3, text: 'Haz seguimiento al estado de tus operaciones', appearDelay: 0.7 },
  { number: 4, text: 'Interactúa con tus clientes y proveedores', appearDelay: 1 },
];

const stepsByCountry = {
  Chile: [1, 2, 3, 4],
  Mexico: [1, 2, 3],
};

const getStepsByCountry = (country) => {
  const stepNumbers = stepsByCountry[country];
  return fullSteps.filter((step) => stepNumbers.includes(step.number));
};

const styles = {
  paperContainer: {
    backgroundImage: `url(${FingoBackground})`,
    width: '100%',
    backgroundSize: 'cover',
    borderRadius: 0,
    margin: '0 0 80px 0',
    padding: '0 0 200px 0',
    position: 'relative',
    boxShadow: 'none',
  },
  paperContainerMobile: {
    backgroundImage: `url(${FingoBackground})`,
    width: '100%',
    backgroundSize: '100% 50%',
    backgroundRepeat: 'no-repeat',
    borderRadius: 0,
    margin: '0 0 100px 0',
    position: 'relative',
    boxShadow: 'none',
  },
  paperPlane: {
    width: '10rem',
    transform: 'scaleX(-1) rotate(30deg)',
  },
  paperPlaneMobile: {
    width: '3rem',
    transform: 'scaleX(-1) rotate(30deg)',
  },
  twistedDottedLine: {
    position: 'absolute',
    width: '10rem',
    right: '0vw',
    top: '18.5vh',
    zIndex: 0,
  },
  twistedDottedLineMobile: {
    position: 'absolute',
    width: '3rem',
    right: 0,
    top: 95,
    zIndex: 0,
  },
  title: {
    width: '73%',
    margin: '90px auto',
    zIndex: 10,
  },
  titleMobile: {
    width: '90%',
    margin: '1.5rem auto 0 auto',
    zIndex: 10,
  },
  stepsContainer: {
    width: '65%',
    margin: '50px auto',
  },
  stepsContainerMobile: {
    width: '95%',
    margin: '1rem auto 0 auto',
  },
};

function FingoSteps() {
  const isMobile = useIsMobile();
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  useIsFirstTimeVisible({ ref, isVisible, setIsVisible });
  const country = useGetCountry();
  const steps = getStepsByCountry(country);
  const floatingComponentProps = {
    speedX: -0.1,
    speedY: -0.1,
    position: 'absolute',
    top: 170,
    right: -10,
    zIndex: 1,
  };
  const floatingComponentPropsMobile = {
    speedX: 0,
    speedY: 0,
    position: 'absolute',
    top: 65,
    right: 30,
    zIndex: 11,
  };
  return (
    <Paper
      sx={isMobile ? styles.paperContainerMobile : styles.paperContainer}
    >
      <Stack
        direction="column"
        spacing={isMobile ? 0.5 : 2}
        sx={isMobile ? styles.titleMobile : styles.title}
      >
        <Typography variant="h2" color="white" zIndex="inherit" fontSize={{ xs: '1.25rem', md: '2rem' }}>
          Obtén tu dinero en <Box display="inline" color="text.primary">menos de 1 día</Box>
        </Typography>
        <Typography
          variant="subtitle2"
          fontSize={{ xs: '0.9rem', md: '1.2rem' }}
          color="white"
          zIndex="inherit"
        >
          Sigue estos simples 4 pasos ¡Es gratis!
        </Typography>
      </Stack>
      <Grid
        ref={ref}
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        sx={isMobile ? styles.stepsContainerMobile : styles.stepsContainer}
        rowGap={2}
        zIndex={10}
      >
        {steps.map((step) => (
          <Grid
            key={step.number}
            item
            xs={6}
            md={3}
            sx={{
              opacity: isVisible ? 1 : 0,
              transition: 'opacity 1s ease-in-out',
              transitionDelay: `${step.appearDelay}s`,
            }}
          >
            <StepCard
              stepNumber={step.number}
              text={step.text}
            />
          </Grid>
        ))}
      </Grid>
      <FloatingComponent
        {...isMobile ? floatingComponentPropsMobile : floatingComponentProps}
      >
        <img src={PaperPlane} alt="paper-plane" style={isMobile ? styles.paperPlaneMobile : styles.paperPlane} />
      </FloatingComponent>
      <img src={TwistedDottedLine} alt="dotted-line" style={isMobile ? styles.twistedDottedLineMobile : styles.twistedDottedLine} />
    </Paper>
  );
}

export default FingoSteps;
