// eslint-disable-next-line import/prefer-default-export
export const FAQ_ITEMS = [
  {
    id: 'faq-n1',
    title: '¿Qué beneficios me trae el software koBros?',
    paragraphs: [
      `
      **##Seguimiento y recordatorio de pagos##**: Facilita el seguimiento de los plazos de pago y el 
      envío automático de recordatorios a los deudores, aumentando la probabilidad de pago 
      puntual.
      `,
      `
      **##Mejora la eficiencia y productividad##**: Automatiza tareas repetitivas, como el envío de 
      recordatorios de pago y la generación de informes, ahorrando tiempo y recursos, y 
      permitiendo a los equipos de cobranza gestionar un mayor volumen de cuentas en menos tiempo.
      `,
      `
      **##Reduce errores##**: Minimiza errores humanos en el seguimiento y registro de deudas, 
      lo que mejora la precisión de los datos.
      `,
      `
      **##Análisis y reportería##**: Facilita la toma de decisiones informadas.
      `,
      `
      **##Mejora la relación de los clientes##**: Permite una gestión más efectiva de las relaciones
      con los deudores, lo que ayuda en la recuperación de deudas.
      `,
    ],
  },
  {
    id: 'faq-n2',
    title: '¿Cómo se integra koBros con los sistemas existentes de mi empresa?',
    paragraphs: [
      `
      Al ingresar las credenciales de la empresa, Fingo tiene acceso a tu información tributaria 
      lo que le permite priorizar la cobranza de tus facturas según montos y fechas de 
      vencimiento. Nosotros centralizamos toda la información del SII y portales de proveedores, 
      pero no hacemos conciliación bancaria ni nos conectamos al sistema contable de la empresa.
      `,
    ],
  },
  {
    id: 'faq-n3',
    title: `¿Qué nivel de seguridad ofrece el software para proteger los datos financieros y la información
     de los clientes?`,
    paragraphs: [
      `
      **##Encriptación de datos##**: Los datos financieros y personales están encriptados tanto en la 
      plataforma como en la base de datos. La encriptación protege la información de accesos no 
      autorizados.
      `,
      `
      **##Acceso basado en roles##**: El software permite la asignación de roles y permisos específicos
       para garantizar que solo el personal autorizado tenga acceso a ciertos datos y funciones.
      `,
    ],
  },
  {
    id: 'faq-n4',
    title: '¿Ofrecen capacitación y soporte técnico para el personal que utilizará el software?',
    paragraphs: [
      `
      Sí. Cada cliente tiene un ejecutivo comercial asignado que puede ayudar con el onboarding y 
      dudas que surjan respecto al uso del software de cobranza.
      `,
    ],
  },
  {
    id: 'faq-n5',
    title: `¿Ofrece koBros capacidades de automatización, como recordatorios de pagos y seguimiento
     de deudas?`,
    paragraphs: [
      `
      Sí. koBros te muestra por prioridades de cobro, según fechas y montos, las facturas que son 
      más urgentes de cobrar. Puedes definir los envíos de correo de cobranza blanda, cobranza 
      preventiva y cobranza dura según los plazos que mejor se adecúen a tu empresa. También 
      koBros consolida la información más importante: te muestra los cambios de estado de tus 
      facturas, cuando tus clientes están con problemas para pagar a tiempo, te alerta de los 
      rechazos y NCs que te lleguen.
      `,
    ],
  },
  {
    id: 'faq-n6',
    title: `¿Qué métricas y reportes proporciona koBros para evaluar la eficacia de la gestión de
     cobranza y mejorar la toma de decisiones?`,
    paragraphs: [
      `
      koBros ofrece un perfil de cada empresa en el que puedes obtener información sobre el 
      comportamiento de pago y riesgo crediticio de tus clientes y potenciales nuevos clientes.
      `,
      `
      koBros se conecta al SII y a portales de proveedores, lo que te permite consolidar toda la 
      información de tus facturas, incluyendo rechazos, notas de crédito, estados y fechas de pago.
      `,
    ],
  },
  {
    id: 'faq-n7',
    title: '¿Cómo se relaciona la plataforma koBros con Fingo? ¿Tiene que ver algo con factoring?',
    paragraphs: [
      `
      La plataforma koBros ofrece un servicio completamente independiente de Fingo. Puedes 
      ingresar a ambas plataformas con el mismo usuario, y puedes compartir información como tu 
      cuenta bancaria y credenciales.
      `,
      `
      La plataforma koBros busca acompañarte en el cobro de tus facturas, no las que estén 
      financiadas por Fingo.
      `,
    ],
  },
  {
    id: 'faq-n8',
    title: '¿Cómo se diferencia koBros de otras soluciones?',
    paragraphs: [
      `
      koBros se diferencia de otras soluciones de cobranza porque busca no solo entregarte las 
      herramientas para cobrar, sino que te acompaña durante el proceso, disponibilizando 
      y consolidando información relevante tanto de tus facturas como de tus clientes y 
      potenciales clientes. Además prioriza la gestión de tus facturas para que puedas manejar tu 
      cobranza de forma eficiente.
      `,
    ],
  },
];
