import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { Box, Stack } from '@mui/material';
import PasswordRequirements from './PasswordRequirements';
import RegisterButton from './RegisterButton';
import {
  REGISTER_INITIAL_VALUES,
  REGISTER_VALIDATION_SCHEMA,
} from '../../../constants/register';
import InputField from './InputField';

function RegisterForm({ onCompleted }) {
  return (
    <Formik
      initialValues={REGISTER_INITIAL_VALUES}
      validationSchema={REGISTER_VALIDATION_SCHEMA}
    >
      {() => (
        <Form>
          <Box sx={{ maxWidth: 400, m: 'auto' }}>
            <Stack spacing={1} pb={2} alignItems="center">
              <InputField
                id="kobros-name"
                name="firstName"
                label="Nombre"
                color="secondary"
                sx={{ width: { xs: 200, smartphone: 200, md: 400 } }}
              />
              <InputField
                id="kobros-last-name"
                name="lastName"
                label="Apellido"
                color="secondary"
                sx={{ width: { xs: 200, smartphone: 200, md: 400 } }}
              />
              <InputField
                id="kobros-phone-number"
                name="phoneNumber"
                label="Número de teléfono"
                color="secondary"
                startAdornment="+56"
                sx={{ width: { xs: 200, smartphone: 200, md: 400 } }}
              />
              <InputField
                id="kobros-email"
                name="email"
                label="Email"
                color="secondary"
                sx={{ width: { xs: 200, smartphone: 200, md: 400 } }}
              />
              <InputField
                id="kobros-password"
                name="password"
                label="Contraseña"
                color="secondary"
                type="password"
                sx={{ width: { xs: 200, smartphone: 200, md: 400 } }}
              />
              <PasswordRequirements />
              <RegisterButton onCompleted={onCompleted}>Crear cuenta</RegisterButton>
            </Stack>
          </Box>
        </Form>
      )}
    </Formik>
  );
}

RegisterForm.propTypes = {
  onCompleted: PropTypes.func.isRequired,
};

export default RegisterForm;
