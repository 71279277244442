import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material';
import { Check, Clear } from '@mui/icons-material';
import PasswordStrength from './PasswordStrength';
import { PASSWORD_VALIDATORS } from '../../../constants/register';

function PasswordRequirements({ name }) {
  const [{ value }] = useField(name);

  return (
    <Stack mt={{ xs: 0, md: 1 }}>
      <PasswordStrength password={value} />
      <List dense sx={{ mx: 'auto' }}>
        {PASSWORD_VALIDATORS.map(({ id, text, test }) => (
          <ListItem key={id} sx={{ p: 0 }}>
            <ListItemIcon>
              {test(value) ? (
                <Check size="small" color="success" />
              ) : (
                <Clear size="small" color="error" />
              )}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}

PasswordRequirements.propTypes = {
  name: PropTypes.string,
};

PasswordRequirements.defaultProps = {
  name: 'password',
};

export default PasswordRequirements;
