const components = {
  MuiButton: {
    styleOverrides: {
      root: {
        height: '35px',
        borderRadius: '8px',
      },
      contained: {
        backgroundColor: 'primary.main',
        color: 'white',
        borderColor: 'primary.main',
      },
      outlined: {
        color: 'primary.main',
        borderColor: 'primary.main',
      },
      navlink: {
        color: 'text.primary',
        height: '35px',
        border: '0px',
      },
      clickedLink: {
        color: 'primary.main',
        height: '35px',
        border: '0px',
      },
      actualView: {
        color: 'white',
        height: '35px',
        border: '0px',
      },
      secondaryContained: {
        backgroundColor: 'secondary.main',
        color: 'white',
        '&:hover': {
          backgroundColor: 'secondary.dark',
          border: 'none',
        },
      },
      outlinedSecondary: {
        color: 'secondary.main',
        borderColor: 'secondary.main',
        border: '1px solid',
        borderRadius: '1.5rem',
        '&:hover': {
          backgroundColor: 'secondary.main',
          color: 'white',
        },
      },
    },
  },
};

export default components;
