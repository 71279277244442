import React from 'react';
import { Avatar, Button, Grid, Typography } from '@mui/material';
import { Email, ReceiptLong, StackedBarChart } from '@mui/icons-material';
import PropTypes from 'prop-types';

function BenefitCard({ Icon, title, paragraph }) {
  return (
    <Grid item direction="column" p={8} spacing={2} lg={4}>
      <Avatar sx={{ bgcolor: 'background.mild', height: 54, width: 54 }}>
        <Icon color="secondary" sx={{ fontSize: 36 }} />
      </Avatar>
      <Typography variant="h3">
        {title}
      </Typography>
      <Typography>
        {paragraph}
      </Typography>
      <Button color="secondary" sx={{ width: 72 }}>
        Ver más
      </Button>
    </Grid>
  );
}

export default function KobrosBenefits() {
  return (
    <Grid container direction="row">
      <BenefitCard
        Icon={ReceiptLong}
        title="Gestiona tus cuentas por cobrar"
        paragraph="Gestiona tu cobranza fácilmente. Mantente al tanto sobre tus clientes, sigue y gestiona tus pagos de manera simple y eficiente."
      />
      <BenefitCard
        Icon={StackedBarChart}
        title="Gestiona según prioridades"
        paragraph="Gracias a nuestro algoritmo de cobranza, podrás gestionar tus cobros por urgencia de pagos y fechas de vencimiento."
      />
      <BenefitCard
        Icon={Email}
        title="Envía mails de recordatorio"
        paragraph="Envía mails recordando a tus clientes sobre los pagos pendientes. Elige el tipo de mail según urgencia de cobranza: preventiva, blanda o dura."
      />
    </Grid>
  );
}

BenefitCard.propTypes = {
  Icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.bool.isRequired,
};
