import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useTheme } from '@emotion/react';

function ThickUnderline({ children, color, thickness, offset }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        textDecoration: `underline ${thickness}`,
        textDecorationColor: color || theme.palette.primary.main,
        textDecorationSkipInk: 'none',
        textUnderlineOffset: offset,
      }}
    >
      {children}
    </Box>
  );
}

ThickUnderline.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  thickness: PropTypes.string,
  offset: PropTypes.string,
};

ThickUnderline.defaultProps = {
  color: null,
  thickness: '20px',
  offset: '-12px',
};

export default ThickUnderline;
