import React from 'react';
import {
  Typography,
  List,
  ListItem,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

const primaryActivities = [
  `
    Realizar el análisis y procesamiento de la información proporcionada por el titular,
    a fin de determinar si es sujeto de crédito, su comportamiento crediticio, capacidad
    de pago y solvencia económica
  `,
  `
    Integrar expedientes, de manera directa o a través de un tercero
  `,
  `
    Actualización de la Base de Datos de esta entidad; así como cualquier
    finalidad compatible con las anteriores
  `,
];

const secondaryActivities = [
  'Prospección comercial',
  'Mercadotecnia',
  'Informar sobre nuevos productos o servicios',
  'Envío de artículos de interés',
  'El cumplimiento de la relación contractual derivado de la solicitud y/o contratación de nuestros productos o servicios',
  'Generar estadísticas respecto de nuestros productos y/o servicios',
  'Generación de expedientes',
];

const financialPersonalData = [
  'Historial crediticio',
  'Cuentas bancarias',
  'Datos de identificación',
  'Datos de contacto',
  'Datos laborales',
  'Datos patrimoniales y/o financieros',
  'Documentos que nos permitan cumplir con la política de conocer a nuestro cliente',
];

const personalData = [
  'Nombre completo',
  'Sexo y nacionalidad',
  'Número de Serie de la Firma Electrónica Avanzada',
  'Teléfonos de contacto y dirección',
  'Cuentas Bancarias',
  'Estado civil',
  'Ocupación',
  'Profesión',
  'Correo electrónico',
  'Contraseña',
  'Edad',
  'Lugar',
  'Fecha de nacimiento',
  'Número de inscripción en el Registro Federal de Contribuyentes',
  'Clave Única de Registro de Población',
];

const trackingTechData = [
  'Identificadores, nombre de usuario y contraseñas de una sesión',
  'Idioma preferido por el usuario',
  'Región en la que se encuentra el usuario',
  'Tipo de navegador del usuario',
  'Tipo de sistema operativo del usuario',
  'Búsquedas realizadas por un usuario',
];

const createListItems = (items) => items.map((item) => (
  <ListItem>
    <Typography variant="body2">{item}</Typography>
  </ListItem>
));

const noticeOfPrivacyFaqs = [
  {
    title: '¿Para qué fines utilizaremos sus datos personales?',
    paragraphs: [
      <Typography variant="body2" gutterBottom>
        Los datos personales que recabamos de usted, los utilizaremos para las siguientes
        finalidades que son necesarias para el servicio que solicita:
      </Typography>,
      <List sx={{ listStyleType: 'disc', pl: 4, '& .MuiListItem-root': { display: 'list-item', padding: 0, '& .MuiTypography-root': { margin: 0 } } }}>
        {createListItems(primaryActivities)}
      </List>,
      <Typography variant="body2" gutterBottom>
        De manera adicional, utilizaremos su información personal para las siguientes
        finalidades secundarias que <strong>no son necesarias</strong> para el servicio
        solicitado, pero que nos permiten y facilitan brindarle una mejor atención:
      </Typography>,
      <List sx={{ listStyleType: 'disc', pl: 4, '& .MuiListItem-root': { display: 'list-item', padding: 0, '& .MuiTypography-root': { margin: 0 } } }}>
        {createListItems(secondaryActivities)}
      </List>,
      <Typography variant="body2" gutterBottom>
        En caso de que no desee que sus datos personales sean tratados para estos
        fines secundarios, desde este momento usted nos puede comunicar lo anterior
        a través del siguiente mecanismo:
      </Typography>,
      <Typography variant="body2" gutterBottom>
        Enviando un correo electrónico a <Link href="mailto:contacto@fingo.com.mx">contacto@fingo.com.mx</Link>

      </Typography>,
      <Typography variant="body2">
        La negativa para el uso de sus datos personales para estas finalidades no podrá
        ser un motivo para que le neguemos los servicios y productos que solicita o
        contrata con nosotros.
      </Typography>,
    ],
  },
  {
    title: '¿Qué datos personales utilizaremos para estos fines?',
    paragraphs: [
      <Typography variant="body2" gutterBottom>
        Para llevar a cabo las finalidades descritas en el presente aviso de privacidad,
        utilizaremos los siguientes datos personales:
      </Typography>,
      <List sx={{ listStyleType: 'disc', pl: 4, '& .MuiListItem-root': { display: 'list-item', padding: 0, '& .MuiTypography-root': { margin: 0 } } }}>
        {createListItems(financialPersonalData)}
      </List>,
      <Typography variant="body2" gutterBottom>
        Además de los datos personales mencionados anteriormente, para las finalidades
        informadas en el presente aviso de privacidad utilizaremos los siguientes datos
        personales considerados como sensibles, que requieren de especial protección:
      </Typography>,
      <List sx={{ listStyleType: 'disc', pl: 4, '& .MuiListItem-root': { display: 'list-item', padding: 0, '& .MuiTypography-root': { margin: 0 } } }}>
        {createListItems(personalData)}
      </List>,
    ],
  },
  {
    title: '¿Con quién compartimos su información personal y para qué fines?',
    paragraphs: [
      <Typography variant="body2" gutterBottom>
        Le informamos que sus datos personales son compartidos dentro y fuera
        del país con las siguientes personas, empresas, organizaciones o autoridades
        distintas a nosotros, para los siguientes fines:
      </Typography>,
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)', verticalAlign: 'bottom' }}>
                Destinatario de los datos personales
              </TableCell>
              <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)', verticalAlign: 'bottom' }}>
                Finalidad
              </TableCell>
              <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)', verticalAlign: 'bottom' }}>
                Requiere del consentimiento
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)', verticalAlign: 'bottom' }}>Fingo Spa</TableCell>
              <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)', verticalAlign: 'bottom' }}>
                Procesamiento de información de los clientes para la automatización de los procesos
              </TableCell>
              <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)', verticalAlign: 'bottom' }}>No</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>,
    ],
  },
  {
    title: '¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?',
    paragraphs: [
      <Typography variant="body2" gutterBottom>
        Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos
        y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la
        corrección de su información personal en caso de que esté desactualizada, sea inexacta o
        incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando
        considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como
        oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos
        se conocen como derechos ARCO.
      </Typography>,
      <Typography variant="body2" gutterBottom>
        Para el ejercicio de cualquiera de los derechos ARCO, usted
        deberá presentar la solicitud respectiva a través del siguiente medio:
      </Typography>,
      <Typography variant="body2" gutterBottom>
        Teléfono 56 4861 2937 y correo electrónico <Link href="mailto:contacto@fingo.com.mx">contacto@fingo.com.mx</Link>
      </Typography>,
      <Typography variant="body2" gutterBottom>
        Con relación al procedimiento y requisitos para el ejercicio de sus
        derechos ARCO, le informamos lo siguiente:
      </Typography>,
      <Typography variant="body2">
        a) ¿A través de qué medios pueden acreditar su identidad el titular y, en su caso,
        su representante, así como la personalidad este último?
      </Typography>,
      <Typography variant="body2" sx={{ marginBottom: 2 }}>
        Vía correo electrónico.
      </Typography>,
      <Typography variant="body2">
        b) ¿Qué información y/o documentación deberá contener la solicitud?
      </Typography>,
      <Typography variant="body2" sx={{ marginBottom: 2 }}>
        Documentos que nos permitan comprobar la identidad del cliente o usuario, así
        como copia de su contrato y la descripción de manera clara y precisa de los datos
        personales respecto de los cuales se busca ejercer Derechos ARCO, así como el
        derecho o derechos que se desea ejercer, lo cual podrá hacerse en el texto del
        correo electrónico o en un documento adjunto escaneado y debidamente firmado al
        final del mismo y rubricado al calce de cada una de las hojas.
      </Typography>,
      <Typography variant="body2">
        c) ¿En cuántos días le daremos respuesta a su solicitud?
      </Typography>,
      <Typography variant="body2" sx={{ marginBottom: 2 }}>
        15 días hábiles.
      </Typography>,
      <Typography variant="body2">
        d) ¿Por qué medio le comunicaremos la respuesta a su solicitud?
      </Typography>,
      <Typography variant="body2" sx={{ marginBottom: 2 }}>
        Vía correo electrónico.
      </Typography>,
      <Typography variant="body2">
        e) ¿En qué medios se pueden reproducir los datos personales que, en su caso, solicite?
      </Typography>,
      <Typography variant="body2" sx={{ marginBottom: 2 }}>
        Medios electrónicos.
      </Typography>,
      <Typography variant="body2">
        f) Ponemos a sus órdenes los siguientes formularios o sistemas para
        facilitar el ejercicio de derechos ARCO:
      </Typography>,
      <Typography variant="body2" sx={{ marginBottom: 2 }}>
        Correo electrónico y teléfono.
      </Typography>,
      <Typography variant="body2">
        g) Para mayor información sobre el procedimiento, ponemos a disposición
        los siguientes medios:
      </Typography>,
      <Typography variant="body2" sx={{ marginBottom: 2 }}>
        Teléfono 56 4861 2937
      </Typography>,
      <Typography variant="body2" gutterBottom>
        Los datos de contacto de la persona o departamento de datos personales,
        que está a cargo de dar trámite a las solicitudes de derechos ARCO, son los siguientes:
      </Typography>,
      <Typography variant="body2">
        a) Nombre de la persona o departamento de datos personales: Atención a clientes
        y usuarios de Fingo
      </Typography>,
      <Typography variant="body2">
        b) Domicilio: calle Varsovia número 36, colonia Juárez, ciudad Ciudad de México,
        municipio o delegación Cuauhtémoc, c.p. 06600, en la entidad de Ciudad de México,
        país México
      </Typography>,
      <Typography variant="body2">
        c) Correo electrónico: <Link href="mailto:contacto@fingo.com.mx">contacto@fingo.com.mx</Link>
      </Typography>,
      <Typography variant="body2">
        d) Número telefónico: 56 4861 2937
      </Typography>,
      <Typography variant="body2">
        Otro dato de contacto: Oficinas de Fingo
      </Typography>,
    ],
  },
  {
    title: '¿Puede revocar su consentimiento para el uso de sus datos personales?',
    paragraphs: [
      <Typography variant="body2" gutterBottom>
        Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el
        tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta
        que no en todos los casos podremos atender su solicitud o concluir el uso de forma
        inmediata, ya que es posible que por alguna obligación legal requiramos seguir
        tratando sus datos personales. Asimismo, usted deberá considerar que para ciertos fines,
        la revocación de su consentimiento implicará que no le podamos seguir prestando el
        servicio que nos solicitó, o la conclusión de su relación con nosotros.
      </Typography>,
      <Typography variant="body2" gutterBottom>
        Para revocar su consentimiento deberá presentar su solicitud a través del siguiente medio:
      </Typography>,
      <Typography variant="body2" gutterBottom>
        A través del correo electrónico <Link href="mailto:contacto@fingo.com.mx">contacto@fingo.com.mx</Link>
      </Typography>,
      <Typography variant="body2" gutterBottom>
        Con relación al procedimiento y requisitos para la revocación
        de su consentimiento, le informamos lo siguiente:
      </Typography>,
      <Typography variant="body2" gutterBottom>
        a) ¿A través de qué medios pueden acreditar su identidad el titular y,
        en su caso, su representante, así como la personalidad este último?
      </Typography>,
      <Typography variant="body2" sx={{ marginBottom: 2 }}>
        Correo electrónico.
      </Typography>,
      <Typography variant="body2" gutterBottom>
        b) ¿Qué información y/o documentación deberá contener la solicitud?
      </Typography>,
      <Typography variant="body2" sx={{ marginBottom: 2 }}>
        Identificación oficial vigente del cliente y copia de contrato que
        acredite la relación jurídica con la entidad.
      </Typography>,
      <Typography variant="body2" gutterBottom>
        c) ¿En cuántos días le daremos respuesta a su solicitud?
      </Typography>,
      <Typography variant="body2" sx={{ marginBottom: 2 }}>
        15 días hábiles.
      </Typography>,
      <Typography variant="body2" gutterBottom>
        d) ¿Por qué medio le comunicaremos la respuesta a su solicitud?
      </Typography>,
      <Typography variant="body2" sx={{ marginBottom: 2 }}>
        A través del correo <Link href="mailto:contacto@fingo.com.mx">contacto@fingo.com.mx</Link>
      </Typography>,
      <Typography variant="body2" gutterBottom>
        e) Para mayor información sobre el procedimiento, ponemos a disposición
        los siguientes medios:
      </Typography>,
      <Typography variant="body2" sx={{ marginBottom: 2 }}>
        Teléfono 56 4861 2937 y correo electrónico <Link href="mailto:contacto@fingo.com.mx">contacto@fingo.com.mx</Link>
      </Typography>,
    ],
  },
  {
    title: '¿Cómo puede limitar el uso o divulgación de su información personal?',
    paragraphs: [
      <Typography variant="body2" gutterBottom>
        Con objeto de que usted pueda limitar el uso y divulgación de su
        información personal, le ofrecemos los siguientes medios:
      </Typography>,
      <Typography variant="body2" gutterBottom>
        Llamando al teléfono 56 4861 2937
      </Typography>,
      <Typography variant="body2" gutterBottom>
        Asimismo, usted se podrá inscribir a los siguientes registros, en
        caso de que no desee obtener publicidad de nuestra parte:
      </Typography>,
      <Typography variant="body2" gutterBottom>
        Registro Público de Usuarios, para mayor información consulte el
        portal de internet de la CONDUSEF.
      </Typography>,
    ],
  },
  {
    title: '¿Cómo es el uso de tecnologías de rastreo en nuestro portal de internet?',
    paragraphs: [
      <Typography variant="body2" gutterBottom>
        Le informamos que en nuestra página de internet utilizamos cookies, web beacons
        u otras tecnologías, a través de las cuales es posible monitorear su comportamiento
        como usuario de internet, así como brindarle un mejor servicio y experiencia al navegar
        en nuestra página. Los datos personales que recabamos a través de estas tecnologías, los
        utilizaremos para los siguientes fines:
      </Typography>,
      <Typography variant="body2" gutterBottom>
        Facilitar la navegación del usuario de nuestros servicios a través del sitio web
      </Typography>,
      <Typography variant="body2" gutterBottom>
        Los datos personales que obtenemos de estas tecnologías de rastreo son los siguientes:
      </Typography>,
      <List sx={{ listStyleType: 'disc', pl: 4, '& .MuiListItem-root': { display: 'list-item', padding: 0, '& .MuiTypography-root': { margin: 0 } } }}>
        {createListItems(trackingTechData)}
      </List>,
      <Typography variant="body2" gutterBottom>
        Estas tecnologías podrán deshabilitarse siguiendo los siguientes pasos: Usted puede borrar,
        bloquear o recibir una advertencia sobre las cookies en los navegadores de Internet más
        comunes. Si usted quiere hacer esto, debe consultar las instrucciones de su navegador
        para aprender más.
      </Typography>,
    ],
  },
  {
    title: '¿Cómo puede conocer los cambios en este aviso de privacidad?',
    paragraphs: [
      <Typography variant="body2" gutterBottom>
        El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones
        derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los
        productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en
        nuestro modelo de negocio, o por otras causas.
      </Typography>,
      <Typography variant="body2" gutterBottom>
        Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente
        aviso de privacidad, a través del sitio web <Link href="https://www.fingo.com.mx" target="_blank" rel="noopener">www.fingo.com.mx</Link>.
      </Typography>,
      <Typography variant="body2" gutterBottom>
        El procedimiento a través del cual se llevarán a cabo las notificaciones sobre cambios
        o actualizaciones al presente aviso de privacidad es el siguiente:
      </Typography>,
      <Typography variant="body2" gutterBottom>
        Dentro del sitio web <Link href="https://www.fingo.com.mx" target="_blank" rel="noopener">www.fingo.com.mx</Link> se
        ingresaran los últimos cambios al aviso de privacidad, manteniendo en
        todo momento el acceso a los avisos de privacidad que se hayan generado previamente.
      </Typography>,
    ],
  },
  {
    title: 'Su consentimiento para el tratamiento de sus datos personales',
    paragraphs: [
      <Typography variant="body2" gutterBottom>
        Consiento que mis datos personales sean tratados de conformidad con los términos
        y condiciones informados en el presente aviso de privacidad.
      </Typography>,
    ],
  },
];

export default noticeOfPrivacyFaqs;
