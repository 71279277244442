import { Stack, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { FiberManualRecord } from '@mui/icons-material';

function DotList({ items, stackProps, itemProps, typographyProps, dotProps }) {
  return (
    <Stack
      spacing={4.5}
      direction="column"
      {...stackProps}
    >
      {items.map((text) => (
        <Stack
          key={text}
          direction="row"
          alignItems="center"
          spacing={3.5}
          {...itemProps}
        >
          <FiberManualRecord {...dotProps} />
          <Typography
            variant="body1"
            color="text.primary"
            lineHeight="1.3rem"
            fontSize="1.1rem"
            {...typographyProps}
          >
            {text}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
}

DotList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  stackProps: PropTypes.shape({}),
  itemProps: PropTypes.shape({}),
  typographyProps: PropTypes.shape({}),
  dotProps: PropTypes.shape({}),
};

DotList.defaultProps = {
  stackProps: {},
  itemProps: {},
  typographyProps: {},
  dotProps: { sx: { fontSize: 12 } },
};

export default DotList;
