import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Fade, Grid, Stack, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import FingoBackground from '../../../assets/brand/images/fingo_background.png';
import { useIsMobile } from '../../../hooks';
import { RegisterButton } from '../buttons';

const muiStyles = {
  intro: {
    backgroundImage: `url(${FingoBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 90%',
    height: 'calc(30vw + 9rem)',
    '@media (max-width:600px)': {
      height: '360px',
      backgroundSize: '100% 80%',
    },
  },
  registerButton: {
    fontWeight: '600',
    border: '1px solid white',
    marginTop: '2rem',
    boxShadow: 'none',
    '&:hover': {
      color: 'primary.main',
      backgroundColor: 'white',
    },
  },
};

function ProductIntro({ name, image }) {
  const isMobile = useIsMobile();
  const location = useLocation();
  const [showImg, setShowImg] = useState(false);
  return (
    <>
      <Grid container sx={muiStyles.intro}>
        <Stack
          direction="row"
          justifyContent="center"
          spacing="5%"
          sx={{
            width: '100%',
            paddingInline: '5%',
            paddingTop: isMobile ? '1rem' : '3rem',
          }}
        >
          <Stack
            direction="column"
            spacing={3}
            alignItems="flex-start"
            sx={{
              width: isMobile ? '50%' : '40%',
              paddingTop: '2rem',
              paddingLeft: isMobile ? '5%' : '7%',
            }}
          >
            <Typography
              variant="h1"
              whiteSpace="pre-line"
              sx={{
                color: 'white',
                width: '100%',
                textAlign: 'left',
              }}
            >
              {name}
            </Typography>
            {!isMobile && (
              <RegisterButton
                id="our-products-register-header-button-desktop"
                label="¡Regístrate!"
                variant="transparent"
              />
            )}
          </Stack>
          <Box
            sx={{
              width: isMobile ? '50%' : '60%',
              textAlign: 'right',
            }}
          >
            <Fade
              in={showImg}
              timeout={1000}
              width="100%"
            >
              <img
                src={image}
                style={{
                  width: isMobile ? '360px' : '720px',
                }}
                alt=""
                onLoad={() => setShowImg(true)}
              />
            </Fade>
          </Box>
        </Stack>
      </Grid>
      {isMobile && (
        <Box
          width="90%"
          sx={{
            marginTop: '-3.5rem',
          }}
        >
          <RegisterButton
            id="our-products-register-header-button-mobile"
            label="¡Regístrate!"
            variant={location.pathname === '/platform'
              ? 'filledSecondary'
              : 'filledPrimary'}
          />
        </Box>
      )}
    </>
  );
}

ProductIntro.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default ProductIntro;
