import React from 'react';
import { Field, useField, useFormikContext } from 'formik';
import { formatRut } from 'react-rut-formatter';
import { TextField } from '@mui/material';

function NationalIdentifierField(props) {
  const [field, meta] = useField('nationalIdentifier');
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const fieldError = meta.touched && Boolean(meta.error);
  const helperText = fieldError ? meta.error : ' ';

  const handleNationalIdentifierChange = (event) => {
    const rawRut = event.target.value;
    const formattedRut = formatRut(rawRut);
    setFieldValue('nationalIdentifier', rawRut === '0-' ? '' : formattedRut);
    setFieldTouched('nationalIdentifier', false);
  };

  const handleNationalIdentifierBlur = () => {
    setFieldTouched('nationalIdentifier', true);
  };

  return (
    <Field
      component={TextField}
      {...field}
      label="RUT"
      type="text"
      placeholder="RUT Empresa"
      error={fieldError}
      helperText={helperText}
      onChange={handleNationalIdentifierChange}
      onBlur={handleNationalIdentifierBlur}
      InputProps={{
        sx: { borderRadius: 20 },
      }}
      FormHelperTextProps={{
        sx: { fontSize: { xs: 6, md: 12 } },
      }}
      size="small"
      {...props}
    />
  );
}

export default NationalIdentifierField;
