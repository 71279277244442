import { Grid } from '@mui/material';
import React from 'react';
import HelpBanner from './HelpBanner';
import HelpCenterBody from './HelpCenterBody';
import HelpForm from './HelpForm';

function HelpCenter() {
  return (
    <Grid container direction="column" alignItems="center">
      <HelpBanner />
      <HelpCenterBody />
      <HelpForm origin="help-center" />
    </Grid>
  );
}

export default HelpCenter;
