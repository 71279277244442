import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import getPlatformUrlByCountry from '../../../helpers/getPlatformUrlByCountry';
import handleUtmRedirect from '../../../helpers/handleUtmRedirect';

const styles = {
  filledPrimary: {
    backgroundColor: 'primary.main',
    color: 'white',
    fontWeight: '600',
    boxShadow: 'none',
    py: '1.3rem',
    px: '2rem',
    '&:hover': {
      backgroundColor: 'primary.dark',
    },
    '@media (max-width:900px)': {
      width: '100%',
      borderRadius: '1.5rem',
    },
  },
  outlinedPrimary: {
    fontWeight: '600',
    color: 'primary.main',
    border: '1px solid #ED265B',
    backgroundColor: 'white',
    boxShadow: 'none',
    py: '1.3rem',
    px: '2rem',
    '&:hover': {
      color: 'white',
      backgroundColor: 'primary.main',
    },
    '@media (max-width:900px)': {
      width: '100%',
      borderRadius: '1.5rem',
    },
  },
  filledSecondary: {
    backgroundColor: 'secondary.main',
    color: 'white',
    fontWeight: '600',
    boxShadow: 'none',
    py: '1.3rem',
    px: '2rem',
    '&:hover': {
      backgroundColor: 'secondary.dark',
    },
    '@media (max-width:900px)': {
      width: '100%',
      borderRadius: '1.5rem',
    },
  },
  outlinedSecondary: {
    fontWeight: '600',
    color: 'secondary.main',
    border: '1px solid #6F28A9',
    backgroundColor: 'white',
    boxShadow: 'none',
    py: '1.3rem',
    px: '2rem',
    '&:hover': {
      color: 'white',
      backgroundColor: 'secondary.main',
    },
    '@media (max-width:900px)': {
      width: '100%',
      borderRadius: '1.5rem',
    },
  },
  transparent: {
    fontWeight: '600',
    border: '1px solid white',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    color: 'white',
    py: '1.3rem',
    px: '2rem',
    '&:hover': {
      color: 'primary.main',
      backgroundColor: 'white',
    },
    '@media (max-width:900px)': {
      width: '100%',
      borderRadius: '1.5rem',
    },
  },
};
function RegisterButton({ label, variant, ...props }) {
  const platformUrl = getPlatformUrlByCountry();
  const onClick = () => handleUtmRedirect(`${platformUrl}/register`);
  return (
    <Button
      sx={{
        ...styles[variant],
      }}
      onClick={onClick}
      {...props}
    >
      {label}
    </Button>
  );
}

RegisterButton.propTypes = {
  label: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

RegisterButton.defaultProps = {
  variant: 'filledPrimary',
};

export default RegisterButton;
