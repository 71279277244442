import { useCallback, useEffect } from 'react';
import { useTracking } from 'react-tracking';

function ButtonTrackerApp({ children }) {
  const { trackEvent } = useTracking();
  const handleButtonClick = useCallback(({ target }) => {
    const idTagged = target.id || target.ownerDocument.activeElement.id;
    if (idTagged) {
      trackEvent({
        action: 'CLICK',
        button: idTagged,
      });
    }
  }, [trackEvent]);
  useEffect(() => {
    window.addEventListener('click', handleButtonClick, false);
    return () => window.removeEventListener('click', handleButtonClick, false);
  }, [handleButtonClick]);
  return children;
}

export default ButtonTrackerApp;
