import { Grid } from '@mui/material';
import React from 'react';
import VideoBanner from './VideoBanner';
import RegisterButtons from './RegisterButtons';
import FingoSteps from './FingoSteps';
import FingoBenefits from './FingoBenefits/FingoBenefits';
import CustomersCarousel from './CustomersCarousel/Carousel';
import HelpVideo from './HelpVideo';
import OurProducts from './OurProducts';
import OurPlatform from './OurPlatform';
import HelpForm from '../HelpCenter/HelpForm';
import { useIsMobile } from '../../hooks';
import ProductSection from './ProductSection';

function Home() {
  const isMobile = useIsMobile();

  return (
    <Grid
      direction="column"
      justifyContent="flex-end"
      alignItems="center"
      container
      spacing={0}
    >
      <VideoBanner />
      {isMobile && <RegisterButtons />}
      <OurProducts />
      <ProductSection />
      <OurPlatform />
      <FingoSteps />
      <HelpVideo />
      <FingoBenefits />
      <CustomersCarousel />
      <HelpForm origin="home" />
    </Grid>
  );
}

export default Home;
