import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

function Bold({ children }) {
  return (
    <Box display="inline" fontWeight={600}>
      {children}
    </Box>
  );
}

Bold.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Bold;
