import React from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment } from '@mui/material';

function HelpTextField({ value, onChange, label, type, name, required, StartIcon, multiline }) {
  const hasIcon = Boolean(StartIcon);
  return (
    <TextField
      multiline={multiline}
      value={value}
      onChange={onChange}
      inputProps={{
        style: {
          alignItems: 'baseline',
          marginLeft: 5,
          height: multiline ? '3rem' : '0.5rem',
        },
      }}
      // eslint-disable-next-line
      InputProps={hasIcon ? {
        startAdornment: (
          <InputAdornment position="start">
            <StartIcon />
          </InputAdornment>
        ),
        sx: { alignItems: 'baseline' },
      } : {}}
      InputLabelProps={{
        shrink: false,
        disabled: true,
        sx: {
          marginLeft: hasIcon ? 5 : 0.5,
          marginTop: multiline ? 0 : '-0.5rem',
        },
      }}
      label={value === '' ? label : ''}
      type={type}
      name={name}
      required={required}
      fullWidth
      sx={{
        backgroundColor: 'background.main',
        borderRadius: multiline ? '1rem' : '1.5rem',
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderRadius: '1.5rem',
            borderColor: 'background.main',
          },
          '&:hover fieldset': {
            borderColor: 'transparent',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'primary.main',
          },
        },
      }}
    />
  );
}

HelpTextField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  required: PropTypes.bool,
  StartIcon: PropTypes.func,
  multiline: PropTypes.bool,
};

HelpTextField.defaultProps = {
  StartIcon: null,
  required: false,
  multiline: false,
};

export default HelpTextField;
