import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

function BicolorBackgroundBox({ children, ...props }) {
  const customBoxSx = useCallback((theme) => ({
    background: `linear-gradient(${theme.palette.text.main} 240px, white 240px)`,
  }));
  return (
    <Box
      sx={customBoxSx}
      px="8%"
      py="4%"
      {...props}
    >
      {children}
    </Box>
  );
}

export default BicolorBackgroundBox;

BicolorBackgroundBox.propTypes = {
  children: PropTypes.node.isRequired,
};
