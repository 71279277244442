import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

function CompositeTypography({ textParts, variant, ...props }) {
  return (
    <Box component="div" {...props}>
      {textParts.map(({ id, text, ...typographyProps }) => (
        <Typography
          key={id}
          component="span"
          variant={variant}
          {...typographyProps}
        >
          {text}
        </Typography>
      ))}
    </Box>
  );
}

CompositeTypography.propTypes = {
  textParts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      text: PropTypes.string,
      color: PropTypes.string,
    }),
  ).isRequired,
  variant: PropTypes.string,
};

CompositeTypography.defaultProps = {
  variant: 'h1',
};

export default CompositeTypography;
