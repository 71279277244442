/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_USER = gql`
  query GetUser {
    getUser {
      id
    }
  }
`;

export const TERMS_AND_CONDITIONS = gql`
  query TermsAndConditions($countryId: Int!) {
    termsAndConditions(last: 1, countryId: $countryId) {
      edges {
        node {
          id
          sections {
            id
            text
            sectionType
          }
        }
      }
    }
  }
`;

export const GET_COUNTRY_BY_NAME = gql`
  query getCountryByName($name: String!) {
    getCountryByName(name: $name) {
      id
      name
      documentTypes {
        id
        code
        name
        shortName
      }
    }
  }
`;
