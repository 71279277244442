import { Fade, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import teamPictures from '../../assets/team';
import { getCdnUrl } from '../../helpers';
import { useIsMobile } from '../../hooks';

const styles = {
  container: {
    maxWidth: '80%',
    marginRight: 'auto',
    marginLeft: '10%',
    marginTop: '4rem',
    '@media (max-width: 600px)': {
      maxWidth: '90%',
      marginLeft: '8%',
    },
  },
  photoContainer: {
    marginTop: '-1.5rem',
    cursor: 'pointer',
    '@media (max-width: 600px)': {
      marginTop: '-1.2rem',
    },
  },
  photos: {
    width: '6.8rem',
    height: '6.8rem',
    marginLeft: '-1.9rem',
  },
  mobilePhoto: {
    width: '4.2rem',
    height: '4.2rem',
    marginLeft: '-1.5rem',
  },
};

function PictureGrid() {
  const isMobile = useIsMobile('sm');
  const [teamPhotos, setTeamPhotos] = useState(teamPictures);
  const [shoudShowGrid, setShoudShowGrid] = useState(false);
  useEffect(() => {
    setShoudShowGrid(
      teamPhotos.reduce((prev, currentObj) => prev && currentObj.loaded, true),
    );
  }, [teamPhotos]);
  const setLoaded = (photo) => {
    const currentPhoto = teamPhotos.find((obj) => obj.photo === photo);
    currentPhoto.loaded = true;
    const newTeamPhoto = [...teamPhotos];
    setTeamPhotos(newTeamPhoto);
  };
  return (
    <Fade
      in={shoudShowGrid}
      timeout={1000}
    >
      <Grid container sx={styles.container} justifyContent="center">
        {teamPhotos.map((picture) => (
          <Grid
            key={picture.photo}
            item
            sx={styles.photoContainer}
            onClick={() => window.open(picture.linkedin)}
          >
            <img
              src={getCdnUrl(picture.photo)}
              alt="avatar"
              style={!isMobile ? styles.photos : styles.mobilePhoto}
              onLoad={() => setLoaded(picture.photo)}
            />
          </Grid>
        ))}
      </Grid>
    </Fade>
  );
}

export default PictureGrid;
