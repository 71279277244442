import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { KobrosLogo } from '../../Utils/logo';
import { ShadowText } from '../../Utils/typography';
import { KobrosDescription } from '../../Utils/text';
import { useIsMobile } from '../../../hooks';

function ProductsKobrosSection() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      alignItems={isMobile ? 'center' : 'end'}
      width={isMobile ? '90%' : '75%'}
      spacing={4}
      mb="6rem"
      mt={{ xs: 5, md: 0 }}
      p={{ xs: 2, md: 0 }}
    >
      <Stack
        flex={2}
        direction="column"
        alignItems={isMobile ? 'flex-start' : 'flex-end'}
        textAlign="end"
        spacing={{ xs: 0, md: 2 }}
        width={{ xs: '100%', md: 'auto' }}
      >
        <KobrosLogo />
        <Typography
          variant="h3"
          sx={{ fontSize: { xs: '1.2rem', md: '1.5rem' } }}
        >Tu mejor asesor de cobranza
        </Typography>
        {!isMobile && (
        <Button
          id="products-kobros-button"
          variant={isMobile ? 'outlinedSecondary' : 'secondaryContained'}
          onClick={() => { navigate('/kobros'); }}
          sx={{ height: '2.5rem', fontWeight: 600 }}
        >
          Explorar koBros
        </Button>
        )}
      </Stack>
      <Stack flex={3} direction="column" spacing={1}>
        <ShadowText fontSize="3rem" opacity="0.2">
          Cobranza
        </ShadowText>
        <Typography variant="h3" color={{ xs: 'secondary.main', md: 'primary.main' }} sx={{ fontSize: { xs: '1.2rem', md: '1.5rem' } }}>
          ¿Aún no conoces koBros?
        </Typography>
        <KobrosDescription pb={{ xs: 4, md: 0 }} />
        {isMobile && (
        <Button
          id="products-explore-kobros-button"
          variant="outlinedSecondary"
          onClick={() => { navigate('/kobros'); }}
          sx={{ height: '2.5rem', fontWeight: 600, width: '100%' }}
        >
          Explorar koBros
        </Button>
        )}
      </Stack>
    </Stack>
  );
}

export default ProductsKobrosSection;
