import React from 'react';
import { useReactiveVar } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import { openExistingAccountDialogVar } from '../../../apollo-client/reactive-variables';
import CompositeTypography from '../../Utils/typography/CompositeTypography';
import { useIsMobile } from '../../../hooks';
import { EXISTING_ACCOUNT_DIALOG_BODY_PARTS } from '../../../constants/kobros/kobros-header';

function ExistingAccountDialog() {
  const openExistingAccountDialog = useReactiveVar(
    openExistingAccountDialogVar,
  );
  const isMobile = useIsMobile();

  return (
    openExistingAccountDialog && (
      <Dialog
        open={openExistingAccountDialog}
        onClose={() => openExistingAccountDialogVar(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ p: 3 }}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography
            variant={isMobile ? 'h3' : 'h2'}
            color="secondary"
            textAlign="center"
          >
            ¡Ya tienes cuenta en Fingo!
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => openExistingAccountDialogVar(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <CompositeTypography
              textParts={EXISTING_ACCOUNT_DIALOG_BODY_PARTS}
              variant="body1"
              textAlign="center"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            sx={{
              fontSize: { xs: 10, smartphone: 12, md: 14 },
              width: { xs: 130, smartphone: 200 },
              height: { xs: 30, md: 41 },
              borderRadius: 20,
            }}
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_FRONTEND_URI}/app/sales/collection/home`,
                '_blank',
              );
            }}
          >
            Ingresar a mi cuenta
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
}

export default ExistingAccountDialog;
