import { Button, Grid } from '@mui/material';
import React from 'react';
import handleUtmRedirect from '../../helpers/handleUtmRedirect';
import getPlatformUrlByCountry from '../../helpers/getPlatformUrlByCountry';
import { WhatsappIcon } from '../../assets/socialMedia/images/whatsapp';
import { whatsappLink } from '../Utils/Links';

const styles = {
  container: {
    width: '90%',
    textAlign: 'center',
    marginBottom: '3rem',
  },
  button: {
    width: '100%',
    marginTop: '0.6rem',
    borderRadius: '1.5rem',
  },
};

function RegisterButtons() {
  const handleWhatsappClick = () => {
    const newWindow = window.open();
    newWindow.opener = null;
    newWindow.location.href = whatsappLink;
  };
  const platformUrl = getPlatformUrlByCountry();
  return (
    <Grid sx={styles.container}>
      <Grid container justifyContent="space-evenly" sx={styles.giveMargin} direction="column">
        <Button
          id="sub-video-register-mobile"
          variant="contained"
          sx={styles.button}
          onClick={() => handleUtmRedirect(`${platformUrl}/register`)}
        >
          Regístrate
        </Button>
        <Button
          id="sub-video-whatsapp-mobile"
          variant="outlined"
          sx={styles.button}
          onClick={handleWhatsappClick}
          endIcon={<WhatsappIcon sx={{ fontSize: 8 }} />}
        >
          Contáctanos
        </Button>
      </Grid>
    </Grid>
  );
}

export default RegisterButtons;
