import { Button, Grid, Typography, Snackbar } from '@mui/material';
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import HelpTextField from './HelpTextField';
import { HELP_CENTER_EMAIL } from '../../graphql/mutations';
import FingoBackground from '../../assets/brand/images/fingo_background.png';
import { useIsMobile } from '../../hooks';
import useContactInformation from '../../hooks/useContactInformation';

const styles = {
  container: {
    backgroundImage: `url(${FingoBackground})`,
    width: '100%',
    backgroundSize: 'cover',
    minHeight: '32rem',
    paddingBottom: '10rem',
    paddingTop: '2.5rem',
  },
  containerMobile: {
    backgroundImage: `url(${FingoBackground})`,
    width: '100%',
    backgroundSize: '100% 70%',
    backgroundRepeat: 'no-repeat',
    margin: '0 0 100px 0',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: '1.5rem',
    boxShadow: '0px 5px 5px rgba(0,0,0,0.25)',
    width: '60%',
    padding: '3rem 4rem',
  },
  formContainerMobile: {
    backgroundColor: 'white',
    borderRadius: '1.5rem',
    boxShadow: '0px 5px 5px rgba(0,0,0,0.25)',
    width: '90%',
    padding: '1.5rem 1.5rem',
    textAlign: 'center',
  },
  inputContainer: {
    marginBottom: '1rem',
    width: '100%',
    '@media (max-width: 600px)': {
      marginBottom: '0.5rem',
    },
  },
  sendButton: {
    margin: '2rem',
    minWidth: '10rem',
  },
  sendButtonMobile: {
    marginTop: '1rem',
    width: '100%',
    borderRadius: '1.5rem',
  },
  title: {
    margin: '2rem',
    color: 'white',
    '@media (max-width: 600px)': {
      textAlign: 'center',
      margin: '1.5rem 0',
    },
  },
  href: {
    color: 'primary.main',
  },
};

function HelpForm({ origin }) {
  const isMobile = useIsMobile();
  const { mail } = useContactInformation();
  const [HelpCenterMail] = useMutation(HELP_CENTER_EMAIL, {
    onCompleted: () => {},
  });
  const [notificationMessage, setNotificationMessage] = useState('');
  const [openNotification, setOpenNotification] = useState(false);
  const [formInputs, setFormInputs] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    question: '',
  });

  const notEmptyValidation = () => {
    let ret = true;
    Object.entries(formInputs).forEach((key) => {
      if (key[0] !== 'phoneNumber') {
        ret = ret && key[1] !== '';
      }
    });
    return !ret;
  };

  const showInvalidFormNotification = () => {
    setNotificationMessage('Debes completar los campos obligatorios');
    setOpenNotification(true);
  };

  const showSuccessNotification = () => {
    setNotificationMessage('¡Recibimos tu mensaje con éxito!');
    setOpenNotification(true);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormInputs((oldstate) => ({
      ...oldstate,
      [name]: value,
    }));
  };

  const onSend = () => {
    if (notEmptyValidation()) {
      showInvalidFormNotification();
      return;
    }
    HelpCenterMail({
      variables: {
        name: formInputs.name,
        email: formInputs.email,
        phoneNumber: formInputs.phoneNumber,
        message: formInputs.question,
      },
    });
    setFormInputs({ name: '', email: '', phoneNumber: '', question: '' });
    showSuccessNotification();
  };
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="space-between"
      sx={isMobile ? styles.containerMobile : styles.container}
    >
      <Grid item sx={styles.title}>
        <Typography variant="h2">¡Contáctanos para saber más!</Typography>
      </Grid>
      <Grid
        container
        item
        sx={isMobile ? styles.formContainerMobile : styles.formContainer}
      >
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 600,
              marginBottom: isMobile ? '1rem' : '2rem',
            }}
          >
            Completa la información y te contactaremos lo antes posible para que
            conversemos:
          </Typography>
          <Grid item sx={styles.inputContainer}>
            <HelpTextField
              value={formInputs.name}
              onChange={onChange}
              label="Nombre"
              type="text"
              name="name"
              required
            />
          </Grid>
          <Grid item sx={styles.inputContainer}>
            <HelpTextField
              value={formInputs.email}
              onChange={onChange}
              label="Correo"
              type="email"
              name="email"
              required
            />
          </Grid>
          <Grid item sx={styles.inputContainer}>
            <HelpTextField
              value={formInputs.phoneNumber}
              onChange={onChange}
              label="Teléfono"
              type="number"
              name="phoneNumber"
            />
          </Grid>
          <Grid item sx={styles.inputContainer}>
            <HelpTextField
              value={formInputs.question}
              onChange={onChange}
              label="¡Cuéntanos! ¿Qué te gustaría saber?"
              type="text"
              name="question"
              required
              multiline
            />
          </Grid>
        </Grid>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="contained"
            onClick={onSend}
            sx={isMobile ? styles.sendButtonMobile : styles.sendButton}
            id={`${origin}-help-center-mail-send`}
          >
            Enviar
          </Button>
          {!isMobile && (
            <Typography variant="body1">
              O si prefieres, escríbenos a{' '}
              <a href={`mailto:${mail}`} style={styles.href}>
                {mail}
              </a>
            </Typography>
          )}
        </Grid>
      </Grid>
      <Snackbar
        open={openNotification}
        autoHideDuration={6000}
        onClose={() => setOpenNotification(false)}
        message={notificationMessage}
      />
    </Grid>
  );
}

HelpForm.propTypes = {
  origin: PropTypes.string.isRequired,
};

export default HelpForm;
