import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      Chile: {
        translation: {
          'Phone prefix': '56',
          'Supplier factoring': 'Pronto pago',
          'supplier factoring': 'pronto pago',
          'text supplier factoring': 'Simula y realiza operaciones de Ordering, Factoring, Confirming y Pronto pago.',
        },
      },
      Mexico: {
        translation: {
          'Phone prefix': '52',
          'Supplier factoring': 'Factoraje a proveedores',
          'supplier factoring': 'factoraje a proveedores',
          'text supplier factoring': 'Simula y realiza operaciones de Ordering, Factoring, Confirming y Factoraje a proveedores.',
        },
      },
    },
    lng: 'Chile',
    fallbackLng: 'Chile',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
