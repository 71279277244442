import { Box, Button, Card, Stack, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { DotList } from '../../Utils/lists';
import Diamond from '../../../assets/home/images/diamond.png';
import { useIsFirstTimeVisible } from '../../../hooks';

const styles = {
  diamond: {
    right: '20rem',
    top: '0rem',
    width: 'calc(3vw + 3.5rem)',
    transform: 'rotate(-15deg)',
  },
};

function FingoBenefitsDesktop({ benefitLists, onClick }) {
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  useIsFirstTimeVisible({ ref, isVisible, setIsVisible });
  return (
    <Stack
      position="relative"
      direction="column"
      alignItems="center"
      spacing={2}
      sx={{
        margin: '3rem 0 10rem 0',
        minWidth: {
          md: 800,
          lg: 1000,
        },
      }}
    >
      <Typography
        variant="h2"
        color="text.primary"
        sx={{
          opacity: isVisible ? 1 : 0,
          transition: 'opacity 1s ease-in-out',
        }}
      >
        Beneficios de Fingo
        <Box
          position="absolute"
          speedY={-0.1}
          speedX={-0.1}
          top={-35}
          right={40}
          zIndex={-1}
        >
          <img src={Diamond} alt="diamond" style={styles.diamond} />
        </Box>
      </Typography>
      <Typography
        variant="subtitle2"
        color="text.primary"
        sx={{
          fontSize: '1.2rem',
          opacity: isVisible ? 1 : 0,
          transition: 'opacity 1s ease-in-out',
          transitionDelay: '0.2s',
        }}
      >
        Disfruta de todo lo que Fingo tiene para ofrecerte a ti y a tu empresa
      </Typography>
      <Stack
        ref={ref}
        direction="row"
        justifyContent="center"
        spacing={6}
        sx={{
          width: '80%',
          paddingTop: '2rem',
          paddingBottom: '1rem',
        }}
      >
        {benefitLists.map((benefits) => (
          <Card
            key={benefits.title}
            elevation={5}
            sx={{
              borderRadius: '1.5rem',
              width: '100%',
              padding: '3rem 3rem',
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? 'translateY(0)' : 'translateY(30px)',
              transition: 'opacity 0.5s ease-in-out, transform 1s ease-in-out',
              transitionDelay: '0.4s',
              maxWidth: 500,
            }}
          >
            <Stack
              direction="column"
              spacing={4}
              sx={{
                width: '100%',
              }}
            >
              <Typography
                variant="body1"
                color="primary"
                fontSize="1.3rem"
                fontWeight={700}
              >
                {benefits.title}
              </Typography>
              <DotList
                items={benefits.list}
                typographyProps={{
                  fontSize: '1rem',
                }}
                stackProps={{
                  spacing: 2,
                }}
              />
            </Stack>
          </Card>
        ))}
      </Stack>
      <Button
        onClick={onClick}
        variant="contained"
        sx={{
          opacity: isVisible ? 1 : 0,
          transition: 'opacity 1s ease-in-out',
          transitionDelay: '0.8s',
          height: '2.5rem',
          width: '15rem',
        }}
      >
        Regístrate en Fingo
      </Button>
    </Stack>
  );
}
FingoBenefitsDesktop.propTypes = {
  benefitLists: PropTypes.arrayOf(PropTypes.any).isRequired, // eslint-disable-line
  onClick: PropTypes.func.isRequired,
};

export default FingoBenefitsDesktop;
