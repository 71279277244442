import React, { useCallback, useMemo, useState } from 'react';
import CredentialsForm from '../components/Kobros/forms/CredentialsForm';
import RegisterForm from '../components/Kobros/forms/RegisterForm';

const useRegisterSteps = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = useCallback(() => setActiveStep((prev) => prev + 1), []);

  const steps = useMemo(
    () => [
      {
        component: <RegisterForm onCompleted={handleNext} />,
      },
      {
        component: (
          <CredentialsForm
            onCompleted={() => {
              window.open(
                `${process.env.REACT_APP_FRONTEND_URI}/app/sales/collection/home`,
                '_blank',
              );
              setActiveStep(0);
            }}
          />
        ),
      },
    ],
    [],
  );
  return { steps, activeStep };
};

export default useRegisterSteps;
