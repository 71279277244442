import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Bold } from '../Utils/typography';
import {
  ProductFactoring,
  ProductConfirming,
  ProductOrdering,
  ProductPayment,
  ProductFactoringSquare,
  ProductOrderingSquare,
  ProductConfirmingSquare,
  ProductPaymentSquare,
} from '../../assets/home/images/products';
import { FloatingComponent } from '../Utils/animations';
import { useIsMobile } from '../../hooks';

const styles = {
  productImage: {
    width: '100%',
  },
};

function OurProducts() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const products = [
    {
      key: 'ordering',
      position: {
        bottom: 0,
        left: 0,
      },
      speedY: 0.02,
      image: isMobile ? ProductOrderingSquare : ProductOrdering,
      order: isMobile ? 2 : 1,
    },
    {
      key: 'factoring',
      position: {
        top: 50,
        right: 0,
      },
      speedY: 0.02,
      image: isMobile ? ProductFactoringSquare : ProductFactoring,
      order: isMobile ? 1 : 2,
    },
    {
      key: 'confirming',
      position: {
        bottom: 30,
        right: 30,
      },
      speedY: 0.04,
      image: isMobile ? ProductConfirmingSquare : ProductConfirming,
      order: isMobile ? 4 : 3,
    },
    {
      key: 'payment',
      position: {
        top: 0,
        left: 30,
      },
      speedY: 0.06,
      image: isMobile ? ProductPaymentSquare : ProductPayment,
      order: isMobile ? 3 : 4,
    },
  ];
  const productImagesDesktopComponent = (
    <Box
      sx={{
        position: 'relative',
        width: '25rem',
        height: '24rem',
      }}
    >
      {products.map((product) => (
        <FloatingComponent
          key={product.key}
          speedY={product.speedY}
          position="absolute"
          width="45%"
          {...product.position}
        >
          <img
            src={product.image}
            alt={`product-${product.key}`}
            style={styles.productImage}
          />
        </FloatingComponent>
      ))}
    </Box>
  );
  const productImagesMobileComponent = (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        position: 'relative',
        width: { xs: '100%', sm: '70%' },
        height: '100%',
      }}
    >
      {products.map((product) => (
        <Grid
          item
          xs={6}
          order={product.order}
          key={product.key}
        >
          <img
            src={product.image}
            alt={`product-${product.key}`}
            style={styles.productImage}
          />
        </Grid>
      ))}
    </Grid>
  );
  const productsDescription = (
    <Stack
      direction="column"
      spacing={isMobile ? 3 : 5}
      width={isMobile ? '100%' : '48%'}
      alignItems={isMobile ? 'center' : 'flex-start'}
      textAlign={isMobile ? 'center' : 'left'}
    >
      <Typography variant="h2">
        Nuestros <Box display="inline" color="primary">productos</Box>
      </Typography>
      <Typography
        variant="subtitle2"
        width={isMobile ? '100%' : '80%'}
        fontSize={{ xs: '0.8rem', md: '1rem' }}
      >
        Con Fingo podrás {' '}
        <Bold>anticipar facturas y órdenes de compra, confirming y {t('supplier factoring')}.</Bold><br />
        ¿Y lo mejor? Rápido, conveniente y 100% online.<br />
        Revisa todos nuestros productos financieros en detalle.
      </Typography>
      <Button
        onClick={() => navigate('/products')}
        variant={isMobile ? 'contained' : 'outlined'}
        color="primary"
        size="large"
        sx={{
          width: isMobile ? '100%' : '10rem',
          height: '2.5rem',
          borderRadius: isMobile ? '1.5rem' : '0.5rem',
        }}
      >
        Ver más
      </Button>
    </Stack>
  );
  return (
    <Stack
      direction={isMobile ? 'column-reverse' : 'row'}
      spacing={5}
      justifyContent={isMobile ? 'center' : 'right'}
      alignItems={isMobile ? 'center' : 'flex-start'}
      sx={{
        mt: isMobile ? 0 : 10,
        mb: isMobile ? 10 : 30,
        px: isMobile ? 2.3 : 0,
      }}
    >
      {isMobile ? productImagesMobileComponent : productImagesDesktopComponent}
      {productsDescription}
    </Stack>
  );
}

export default OurProducts;
