import React, { useState } from 'react';
import { Field, useField, useFormikContext } from 'formik';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

function PasswordField(props) {
  const [showPassword, setShowPassword] = useState(false);
  const [field, meta] = useField('password');
  const { getFieldProps } = useFormikContext();

  const togglePassword = () => setShowPassword((prev) => !prev);

  const fieldError = meta.touched && Boolean(meta.error);
  const helperText = fieldError ? meta.error : ' ';

  return (
    <Field
      component={TextField}
      {...field}
      type={showPassword ? 'text' : 'password'}
      error={fieldError}
      helperText={helperText}
      InputProps={{
        ...getFieldProps('password'),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={togglePassword}
              onMouseDown={togglePassword}
              color="inherit"
            >
              {showPassword ? (
                <Visibility fontSize="small" />
              ) : (
                <VisibilityOff fontSize="small" />
              )}
            </IconButton>
          </InputAdornment>
        ),
        sx: { borderRadius: 20 },
      }}
      FormHelperTextProps={{
        sx: { fontSize: { xs: 6, md: 12 } },
      }}
      size="small"
      {...props}
    />
  );
}

export default PasswordField;
