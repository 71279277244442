import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Stack,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import CalendarBook from '../../calendar/CalendarBook';

function CollectionDemoCalendar({ open, onClose, path }) {
  return (
    open && (
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{ sx: {
          borderRadius: 10,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          maxHeight: '90vh',
          width: {
            xs: '100%',
            sm: '80%',
            md: '60%',
            lg: '50%',
          },
        } }}
        maxWidth="sm"
      >
        <DialogTitle
          sx={{
            bgcolor: 'secondary.main',
            py: 3,
            backgroundColor: 'secondary.main',
          }}
          width="100%"
          id="alert-dialog-title"
        >
          <Typography variant="h4" align="center" color="white" fontWeight={500} width="100%">
            Agenda una demo con nosotros
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 10,
              color: 'white',
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 3,
            mx: 3,
          }}
        >
          <Stack
            direction="column"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >

            <Typography variant="body2" align="center" paddingBottom={2}>
              Elige en el calendario la fecha qué más te acomode
              para que te enseñemos a usar koBros:
            </Typography>
            <Box
              width="100%"
            >
              <CalendarBook path={path} />
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>
    )
  );
}

CollectionDemoCalendar.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
};

export default CollectionDemoCalendar;
