import { styled } from '@mui/system';
import { Link as MuiLink } from '@mui/material';

const PrimaryLink = styled(MuiLink)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'underline',
  // ':hover': {
  //   color: theme.palette.secondary.dark,
  // },
  zIndex: 99,
}));

export default PrimaryLink;
