import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Grid,
  Tooltip,
  Drawer,
  ListItemText,
  ListItem,
  List,
  Button,
  Stack,
} from '@mui/material';
import { MenuSharp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import handleUtmRedirect from '../../../helpers/handleUtmRedirect';

const style = {
  appBar: {
    backgroundColor: 'white',
    maxHeight: '4rem',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 2rem',
    backgroundColor: 'white',
  },
  logo: {
    width: 110,
  },
  logoContainer: {
    cursor: 'pointer',
    paddingLeft: '0.5rem',
    display: 'flex',
    alignItems: 'center',
  },
};

function MobileNavbar({ buttons, pages, Logo }) {
  const navegite = useNavigate();
  const [barOpen, setBarOpen] = useState(false);
  const handleListItemClick = (page) => {
    setBarOpen(false);
    navegite(page.url);
  };
  return (
    <>
      <AppBar elevation={0} sx={style.appBar} position="sticky">
        <Toolbar sx={style.toolbar}>
          <Logo />
          <Grid container alignItems="center" justifyContent="flex-end">
            <Button
              id="open-sidebar-mobile"
              onClick={() => setBarOpen(!barOpen)}
            >
              <Tooltip
                title={barOpen ? 'Cerrar barra lateral' : 'Abrir barra lateral'}
              >
                <MenuSharp />
              </Tooltip>
            </Button>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        key="drawer-menu"
        anchor="right"
        onClose={() => setBarOpen(false)}
        open={barOpen}
        variant="temporary"
      >
        <List>
          <Stack spacing={2} m={2}>
            {pages.map((page) => (
              <Button
                key={page.title}
                id={`sidebar-${page.id}-mobile`}
                sx={{ textAlign: 'left' }}
                onClick={() => handleListItemClick(page)}
              >
                <ListItemText primary={page.title} />
              </Button>
            ))}
          </Stack>
          {buttons.map(({ title, id, url, queryParams, ...rest }) => (
            <ListItem key={title}>
              <Button
                id={`sidebar-${id}-mobile`}
                onClick={() => handleUtmRedirect(url)}
                sx={{ minWidth: '100%' }}
                {...rest}
              >
                {title}
              </Button>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
}

MobileNavbar.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  pages: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  Logo: PropTypes.node.isRequired,
};

export default MobileNavbar;
