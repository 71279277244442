import { Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import ArrowImage from '../../../assets/home/images/arrow.png';

function ArrowList({ items }) {
  return (
    <Stack spacing={3}>
      {items.map((text) => (
        <Grid
          key={text}
          container
          direction="row"
          sx={{
            alignContent: 'flex-start',
            justifyContent: 'space-evenly',
            alignItems: 'flex-start',
          }}
        >
          <img
            src={ArrowImage}
            alt="arrow"
            style={{
              width: '2.5vw',
              height: 'auto',
            }}
          />
          <Typography
            variant="body1"
            width="80%"
            lineHeight="1.3rem"
            fontSize="1.1rem"
          >
            {text}
          </Typography>
        </Grid>
      ))}
    </Stack>
  );
}

ArrowList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ArrowList;
