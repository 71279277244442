const breakpoints = {
  values: {
    xs: 0,
    smartphone: 320,
    s: 376,
    phone: 480,
    sm: 600,
    ipad: 600,
    m: 750,
    md: 900,
    lg: 1200,
    desktop: 1024,
    xl: 1536,
  },
};

export default breakpoints;
