import { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import {
  ACTIVATE_COLLECTION_PLAN,
  UPLOAD_CREDENTIALS,
} from '../graphql/mutations';
import { getVariable } from '../helpers/local-storage';

const DEFAULT_COLLECTION_PLAN_ID = '1';

const useUploadCollectionCredentials = (onCompleted) => {
  const token = useMemo(() => getVariable('token'), []);

  // eslint-disable-next-line operator-linebreak
  const [activateCollectionPlan, { loading: activateCollectionPlanLoading }] =
    useMutation(ACTIVATE_COLLECTION_PLAN, {
      variables: {
        planId: DEFAULT_COLLECTION_PLAN_ID,
      },
      context: {
        headers: {
          Authorization: `JWT ${token}`,
        },
      },
      onCompleted: () => {
        onCompleted();
      },
    });

  // eslint-disable-next-line operator-linebreak
  const [uploadCredentials, { loading: uploadCredentialsLoading }] =
    useMutation(UPLOAD_CREDENTIALS, {
      variables: {
        credentialProvider: 1,
        source: 'Fingo',
        origin: 'kobros',
      },
      context: {
        headers: {
          Authorization: `JWT ${getVariable('token')}`,
        },
      },
      onCompleted: () => {
        activateCollectionPlan();
      },
    });

  const submit = useCallback((username, password) => {
    uploadCredentials({ variables: { username, password } });
  }, []);

  const loading = useMemo(
    () => activateCollectionPlanLoading || uploadCredentialsLoading,
    [activateCollectionPlanLoading, uploadCredentialsLoading],
  );

  return { loading, submit };
};

export default useUploadCollectionCredentials;
