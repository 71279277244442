import React from 'react';
import { getCdnUrl } from '../../helpers';
import { ProductIntro } from '../Utils/headers';

const name = 'Nuestra \n plataforma';

function OurPlatformHeader() {
  return (
    <ProductIntro
      name={name}
      image={getCdnUrl('/images/pc_platform.png')}
    />
  );
}

export default OurPlatformHeader;
