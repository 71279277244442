import { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';

const PixelProvider = ({ children }) => {
  const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  const pixelId = '1174177536408720';
  if (process.env.NODE_ENV === 'production') {
    ReactPixel.init(pixelId, advancedMatching, options);
  }
  useEffect(() => {
    ReactPixel.pageView();
  }, []);
  return children;
};

export default PixelProvider;
