import React from 'react';
import { Button, Grid, Paper, Stack, Typography } from '@mui/material';
import CompositeTypography from '../Utils/typography/CompositeTypography';
import { BODY_PARTS, TITLE_PARTS, TITLE_PARTS2 } from '../../constants/kobros/kobros-header';
import CollectionDemoForm from './forms/CollectionDemoForm';
import getPlatformUrlByCountry from '../../helpers/getPlatformUrlByCountry';
import handleUtmRedirect from '../../helpers/handleUtmRedirect';

function KobrosHeader() {
  const platformUrl = getPlatformUrlByCountry();

  return (
    <Grid
      container
      id="home-solutions"
      sx={{
        pt: { xs: 8, md: 6 },
        pb: { xs: 6, md: 0 },
        bgcolor: '#FFF',
      }}
    >
      <Grid container direction="column" textAlign="center" pb={4}>
        <CompositeTypography
          textParts={TITLE_PARTS}
        />
        <CompositeTypography
          textParts={TITLE_PARTS2}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        display="flex"
        flexDirection="column"
        gap={{ xs: 2, md: 4 }}
        mt={{ md: 9 }}
        pl={{ xs: 2, md: '7%' }}
        pr={{ xs: 0, md: 11 }}
        py={4}
      >
        <CompositeTypography
          textParts={BODY_PARTS}
          variant="body3"
          px={{ xs: 3, md: 0 }}
          maxWidth={{ md: 580 }}
          width={{ xs: 'auto', md: '90%' }}
          textAlign={{ xs: 'center', md: 'left' }}
        />
        <Typography
          color="secondary"
          fontWeight={500}
          fontSize={18}
        >
          <b>
            ¡No pierdas más tiempo y cobra inteligente con koBros!
          </b>
        </Typography>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          sx={{
            fontSize: { xs: 18, md: 18 },
            width: { xs: 140, smartphone: 250 },
            height: { xs: 30, md: 41 },
            borderRadius: 20,
            mt: 1,
          }}
          onClick={() => handleUtmRedirect(`${platformUrl}/collection-register`)}
        >
          <b>
            Explora koBros →
          </b>
        </Button>
      </Grid>
      <Grid item xs={12} md={6} mt={{ xs: 3, md: 4 }} px={{ xs: 3, md: 0 }}>
        <Stack
          component={Paper}
          direction="column"
          alignItems="center"
          spacing={3}
          maxWidth={600}
          elevation={4}
          py={4}
          mx={{ xs: 'auto', md: 0 }}
        >
          <Typography
            variant="body2"
            textAlign="center"
            fontWeight="bold"
            width={300}
          >
            Ingresa tus datos para solicitar una demo. ¡Te contactaremos a la
            brevedad!
          </Typography>
          <CollectionDemoForm />
        </Stack>
      </Grid>
    </Grid>
  );
}

export default KobrosHeader;
