import React from 'react';
import { Box, Card, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useIsMobile } from '../../../hooks';

const styles = {
  card: {
    height: '21rem',
    width: '22rem',
    margin: '2rem 0',
    borderRadius: '0.8rem',
    padding: '2.5rem 2.5rem',
    '&:hover': {
      boxShadow: '0 0 10px #E62E66',
      transform: 'scale(1.1)',
      transition: 'all 0.2s ease-in-out',
    },
  },
  cardMobile: {
    height: '20rem',
    width: '20rem',
    margin: '2rem 0',
    borderRadius: '0.8rem',
    padding: '2rem 2rem',
  },
  cardContent: {
    paddingBlock: '0.5rem',
    paddingInline: '0.7rem',
    height: '100%',
  },
  cardTitle: {
    fontWeight: 700,
    textAlign: 'left',
    fontSize: '1.1rem',
    lineHeight: '1.3rem',
    '@media (max-width:600px)': {
      fontSize: '1.1rem',
    },
    marginBottom: '0.5rem',
  },
  cardSubtitle: {
    textAlign: 'left',
    fontSize: '0.9rem',
    lineHeight: '1.3rem',
    '@media (max-width:600px)': {
      fontSize: '0.8rem',
    },
  },
  testmonialText: {
    lineHeight: '1.1rem',
    color: '#727272',
    '@media (max-width:600px)': {
      fontSize: '0.9rem',
    },
  },
};

function CarouselCard({ ...props }) {
  const isMobile = useIsMobile();
  return (
    <Card sx={isMobile ? styles.cardMobile : styles.card} elevation={5}>
      <Stack
        direction="column"
        justifyContent="space-between"
        height="100%"
      >
        <Stack>
          <Box
            sx={{
              width: '4.2rem',
              margin: '0 0 0.5rem 0',
            }}
          >
            <img
              src={props.image}
              alt="testimonial"
            />
          </Box>
          <Stack
            minHeight="4rem"
          >
            {props.title && (
              <Typography sx={styles.cardTitle}>
                {props.title}
              </Typography>
            )}
            <Typography sx={props.title ? styles.cardSubtitle : styles.cardTitle}>
              {props.subtitle}
            </Typography>
          </Stack>
        </Stack>
        <Box
          sx={{
            paddingRight: '15%',
          }}
        >
          <Typography textAlign="left" sx={styles.testmonialText}>
            &quot;{props.testimonial}&quot;
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
}

CarouselCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  testimonial: PropTypes.string.isRequired,
};

export default CarouselCard;
