import React, { useState } from 'react';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { WhatsApp } from '@mui/icons-material';
import CollectionDemoCalendar from './dialogs/CollectionDemoCalendar';

export default function KobrosFooter() {
  const [openCalendar, setOpenCalendar] = useState(false);
  return (
    <Stack
      px={{ lg: '20%', md: '5%' }}
      py={16}
      direction="row"
      bgcolor="secondary.main"
      color="white"
      alignItems="center"
      justifyContent="space-around"
    >
      <Stack textAlign="center" spacing={4}>
        <Typography variant="h3">
          ¿Tienes dudas?
        </Typography>
        <Button
          variant="contained"
          color="secondarynt"
          sx={{ py: 3, borderRadius: 10 }}
          onClick={() => setOpenCalendar(true)}
        >
          <Typography variant="h4" sx={{ color: 'text.main' }}>
            Solicitar información
          </Typography>
        </Button>
      </Stack>
      <Stack textAlign="center" spacing={2}>
        <Typography variant="h3">
          ¡Escríbenos por whatsapp!
        </Typography>
        <IconButton onClick={() => window.open('https://api.whatsapp.com/send?phone=56937230288&text=%F0%9F%91%8B%20Hola%20buenos%20d%C3%ADas!%20Necesito%20comunicarme%20con%20alguna%20persona%20dentro%20de%20koBros!%20%F0%9F%91%80')}>
          <WhatsApp sx={{ height: 64, fontSize: 64, color: 'white' }} />
        </IconButton>
      </Stack>
      <CollectionDemoCalendar
        open={openCalendar}
        onClose={() => setOpenCalendar(false)}
        path=""
      />
    </Stack>
  );
}
