import React from 'react';
import { Stack, Typography } from '@mui/material';

export default function CollectionProblems() {
  return (
    <Stack
      bgcolor="background.light"
      width="100%"
      py={8}
    >
      <Typography variant="h3" color="secondary.main" alignSelf="center">¡Que cobrar nunca más sea un problema!</Typography>
      <br />
      <Typography fontSize="18px" width="50%" alignSelf="center" textAlign="center">
        Ordena y gestiona tu cobranza, recuerda por correo a tus clientes,
        cobra en base a prioridades, obtén la situación financiera de tus clientes y mucho más
      </Typography>
    </Stack>
  );
}
