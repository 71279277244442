import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { checkRut } from 'react-rut-formatter';
import { Form, Formik } from 'formik';
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import SiiLogo from '../../../assets/sii_logo.png';
import useUploadCollectionCredentials from '../../../hooks/useUploadCollectionCredencials';
import NationalIdentifierField from '../../fields/NationalIdentifierField';
import PasswordField from '../../fields/PasswordField';
import { openCollectionRegisterDialogVar } from '../../../apollo-client/reactive-variables';

const validationSchema = Yup.object().shape({
  nationalIdentifier: Yup.string()
    .required('El RUT es obligatorio')
    .test('isValid', 'El RUT no es válido', (value) => checkRut(value)),
  password: Yup.string().required('La contraseña es obligatoria'),
});

function CredentialsForm({ onCompleted }) {
  // eslint-disable-next-line operator-linebreak
  const { loading, submit } = useUploadCollectionCredentials(onCompleted);

  return (
    <Stack
      direction="column"
      bgcolor="white"
      justifyContent="flex-start"
      p={{ xs: 0, ipad: 3 }}
      spacing={{ xs: 1, md: 3.5 }}
      textAlign="center"
      alignItems="center"
    >
      <Box
        component="img"
        sx={{
          maxHeight: { xs: 50, md: 80 },
          maxWidth: 'auto',
          objectFit: 'contain',
        }}
        alt="SiiLogo"
        src={SiiLogo}
      />
      <Typography fontSize={20} lineHeight={1.2}>
        Ingresa tus credenciales del SII para desplegar tu información de
        clientes y que realices gestiones:
      </Typography>
      <Formik
        initialValues={{ nationalIdentifier: '', password: '' }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          submit(values.nationalIdentifier, values.password);
          setSubmitting(false);
          resetForm();
          openCollectionRegisterDialogVar(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Stack
              spacing={1}
              alignItems="center"
              width={{ xs: 150, smartphone: 190, ipad: 400 }}
            >
              <NationalIdentifierField
                color="secondary"
                sx={{ width: '80%' }}
              />
              <PasswordField
                color="secondary"
                placeholder="Clave SII"
                sx={{ width: '80%' }}
              />
              <Button
                id="credentials-create-kobros-account"
                variant="contained"
                color="secondary"
                type="submit"
                disabled={isSubmitting}
                sx={{
                  width: { xs: '80%', smartphone: 180, md: 260 },
                  height: { xs: 30, md: 40 },
                  fontSize: { xs: 10, smartphone: 12, md: 14 },
                  borderRadius: 16,
                }}
              >
                {loading ? (
                  <CircularProgress color="secondary" size="small" />
                ) : (
                  'Crear cuenta'
                )}
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Stack>
  );
}

CredentialsForm.propTypes = {
  onCompleted: PropTypes.func.isRequired,
};

export default CredentialsForm;
