import { Stack } from '@mui/material';
import React from 'react';
import KobrosHeader from './KobrosHeader';
import Solutions from './Solutions';
import MrKobrosAdvice from './MrKobrosAdvice';
import CollectionProblems from './CollectionProblems';
import KobrosBenefits from './KobrosBenefits';
import KobrosFooter from './KobrosFooter';
import FAQ from './FAQ';
import EaseInAndOut from '../Utils/EaseInAndOut';

function Kobros() {
  return (
    <Stack direction="column" mx="auto" spacing={16}>
      <EaseInAndOut>
        <KobrosHeader />
      </EaseInAndOut>
      <EaseInAndOut>
        <MrKobrosAdvice />
      </EaseInAndOut>
      <EaseInAndOut>
        <Solutions />
      </EaseInAndOut>
      <EaseInAndOut>
        <CollectionProblems />
      </EaseInAndOut>
      <EaseInAndOut>
        <KobrosBenefits />
      </EaseInAndOut>
      <EaseInAndOut>
        <FAQ />
      </EaseInAndOut>
      <EaseInAndOut>
        <KobrosFooter />
      </EaseInAndOut>
    </Stack>
  );
}

export default Kobros;
