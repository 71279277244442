import React from 'react';
import { Box, Typography } from '@mui/material';
import { MexRates } from '../../../assets/legal';

const costAndComissionFAQs = [
  {
    title: 'Costos y comisiones',
    paragraphs: [
      <Typography variant="body2">
        En Fingo no cobramos comisiones por apertura de línea ni por operación. El único costo
        que cobramos son los intereses asociados a los días de la operación.
        Eventualmente podrían existir costos por mora si el cliente no paga la operación a tiempo.
      </Typography>,
      <Typography variant="body2" sx={{ color: '#ED265B' }}>
        El uso de la plataforma de Fingo no genera costo ni comisión.
      </Typography>,
    ],
  },
  {
    title: 'Tasa de descuento',
    paragraphs: [
      <Box
        component="img"
        src={MexRates}
        alt=""
        sx={{
          maxHeight: 140,
          maxWidth: 'auto',
          objectFit: 'contain',
        }}
      />,
    ],
  },
  {
    title: '¿Qué es tasa fija?',
    paragraphs: [
      <Typography variant="body2">
        Es un tipo de interés que no varía durante el tiempo que dura el acuerdo de
        factoring. Desde el momento en que se establece la tasa hasta que se completa el
        acuerdo, el porcentaje de interés aplicado a los adelantos de efectivo permanece
        igual.
      </Typography>,
    ],
  },
];

export default costAndComissionFAQs;
