import { makeVar } from '@apollo/client';
import { getVariable } from '../helpers/local-storage';

export const token = makeVar(getVariable('token'));

export const refreshToken = makeVar(getVariable('refreshToken'));

export const tokenExpire = makeVar(getVariable('tokenExpire'));

export const openCollectionDemoDialogVar = makeVar(false);

export const openExistingAccountDialogVar = makeVar(false);

export const openCollectionRegisterDialogVar = makeVar(false);
