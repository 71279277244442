import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import FAQs from '../Utils/FAQ';
import { useIsMobile } from '../../hooks';

const styles = {
  container: {
    width: '65%',
    marginBottom: '5rem',
    '@media(max-width:600px)': {
      width: '90%',
      marginBottom: '2rem',
    },
  },
  acordion: {
    border: '0px',
    width: '100%',
  },
  acordionSumary: {
    border: 0,
  },
  acordionDetails: {
    border: 0,
    padding: '7px',
  },
};

function FAQ({ search }) {
  const isMobile = useIsMobile('sm');
  const filteredFAQs = FAQs.filter(
    (faq) => (faq.title.toLowerCase().includes(search.toLowerCase())
      || faq.paragraphs.map(
        (paragraph) => paragraph.toLowerCase().includes(search.toLowerCase()),
      ).includes(true)),
  );
  return (
    <Grid container sx={styles.container} justifyContent="center">
      {filteredFAQs.map((question) => (
        <Accordion key={question.title} sx={styles.acordion} elevation={0}>
          <AccordionSummary
            sx={styles.acordionSumary}
            expandIcon={<Typography color="primary" variant="h3">+</Typography>}
          >
            <Typography variant={isMobile ? 'h3' : 'subtitle2'} sx={{ fontWeight: 'bold' }}>
              {question.title}
            </Typography>
          </AccordionSummary>
          {question.paragraphs.map((paragraph) => (
            <AccordionDetails key={paragraph} sx={styles.acordionDetails}>
              <Typography variant="body2" fontSize={{ xs: '0.8rem', md: '0.9rem' }}>
                {paragraph}
              </Typography>
            </AccordionDetails>
          ))}
        </Accordion>
      ))}
    </Grid>
  );
}

FAQ.propTypes = {
  search: PropTypes.string.isRequired,
};

export default FAQ;
