import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Card, Stack, Typography } from '@mui/material';
import { useIsFirstTimeVisible } from '../../../hooks';
import { DotList } from '../../Utils/lists';
import Diamond from '../../../assets/home/images/diamond.png';

function FingoBenefitsMobile({ benefitLists, onClick }) {
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  useIsFirstTimeVisible({ ref, isVisible, setIsVisible });
  return (
    <Stack
      ref={ref}
      direction="column"
      alignItems="center"
      textAlign="center"
      spacing={2}
      sx={{
        width: '90%',
        marginBottom: '5rem',
      }}
    >
      <Typography
        variant="h2"
        color="text.primary"
        sx={{
          opacity: isVisible ? 1 : 0,
          transition: 'opacity 1s ease-in-out',
          position: 'relative',
        }}
      >
        Beneficios de Fingo
        <Box
          position="absolute"
          top={-10}
          right={-65}
        >
          <img
            src={Diamond}
            alt="diamond"
            style={{
              width: '3rem',
              transform: 'rotate(-15deg)',
            }}
          />
        </Box>
      </Typography>
      <Typography
        variant="subtitle2"
        color="text.primary"
        sx={{
          fontSize: { xs: '0.9rem' },
          opacity: isVisible ? 1 : 0,
          transition: 'opacity 1s ease-in-out',
          transitionDelay: '0.2s',
        }}
      >
        Disfruta de todo lo que Fingo tiene para ofrecerte a ti y a tu empresa
      </Typography>
      <Stack
        direction="column"
        spacing={2}
        textAlign="left"
        paddingBottom={1}
        sx={{
          width: '100%',
        }}
      >
        {benefitLists.map((benefits) => (
          <Card
            key={benefits.title}
            elevation={5}
            sx={{
              padding: '1.5rem',
              borderRadius: '1rem',
            }}
          >
            <Stack
              direction="column"
              spacing={2}
            >
              <Typography
                color="primary"
                fontSize={{ xs: '1rem', md: '1.3rem' }}
                fontWeight={700}
              >
                {benefits.title}
              </Typography>
              <DotList
                items={benefits.list}
                stackProps={{ spacing: 1 }}
                itemProps={{ spacing: 2 }}
                typographyProps={{ fontSize: { xs: '0.85rem' } }}
              />
            </Stack>
          </Card>
        ))}
      </Stack>
      <Button
        onClick={onClick}
        variant="contained"
        sx={{
          opacity: isVisible ? 1 : 0,
          transition: 'opacity 1s ease-in-out',
          transitionDelay: '0.8s',
          height: '2.5rem',
          width: '100%',
          borderRadius: '1.5rem',
        }}
      >
        Regístrate en Fingo
      </Button>
    </Stack>
  );
}

FingoBenefitsMobile.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  benefitLists: PropTypes.arrayOf(PropTypes.any).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default FingoBenefitsMobile;
