import React from 'react';
import { Typography } from '@mui/material';
import { formatText } from '../../../helpers';

const KOBROS_DESCRIPTION = `**koBros** es una herramienta que pone a tu disposición un 
**asesor de cobranza con inteligencia artificial** que te ayuda a tomar 
**mejores decisiones con tus clientes**, cobrar de forma **eficiente** y contar con los **flujos** 
cuando los necesitas.jlinejline **¡Obtén un mes de prueba gratis y cancela cuando quieras!**
`;

function KobrosDescription(props) {
  return (
    <Typography
      variant="subtitle2"
      fontSize={{ xs: '0.8rem', md: '1rem' }}
      {...props}
    >
      {formatText(KOBROS_DESCRIPTION)}
    </Typography>
  );
}

export default KobrosDescription;
