import React from 'react';
/* eslint-disable import/prefer-default-export */
export const formatText = (text, color = '#323232') => {
  const final = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
    .replace(/__(.*?)__/g, '<u>$1</u>')
    .replace(/~~(.*?)~~/g, '<s>$1</s>')
    .replace(/jline/g, '</br>')
    .replace(/##(.*?)##/g, `<span style="color:${color} ;">$1</span>`);

  return <div dangerouslySetInnerHTML={{ __html: final }} />; // eslint-disable-line
};

export const createQueryString = (params) => Object.entries(params)
  .filter(([, value]) => Boolean(value))
  .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
  .join('&');
