import { Box, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from '../../../hooks';
import { RegisterButton } from '../../Utils/buttons';
import { ShadowText } from '../../Utils/typography';
import productsArray from '../ProductsArray';

const styles = {
  container: {
    maxWidth: '100%',
    margin: '-5rem 0 4rem 0',
    padding: '0 6rem',
    '@media (max-width: 1150px)': {
      margin: '-2rem 0 3rem 0',
      padding: '0 2rem',
    },
    '@media (max-width: 900px)': {
      margin: '4rem 0 3rem 0',
      padding: '0',
    },
  },
};

function ProductBody() {
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        width: isMobile ? '90%' : '100%',
      }}
      spacing={1}
    >
      <Grid
        container
        rowGap={8}
        sx={styles.container}
      >
        {productsArray.map((product) => (
          <Grid
            key={product.backTitle}
            container
            item
            direction="column"
            xs={12}
            md={6}
            sx={{
              px: '0.5rem',
            }}
          >
            <ShadowText fontSize="3rem" opacity="0.2">
              {t(product.backTitle)}
            </ShadowText>
            <Typography
              variant="h3"
              color="primary"
              sx={{
                ml: isMobile ? 0 : '6.5rem',
                fontSize: { xs: '1.2rem', md: '1.5rem' },
              }}
            >
              {t(product.title)}
            </Typography>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                mt: '1rem',
              }}
            >
              <Box
                sx={{
                  backgroundColor: 'background.main',
                  borderRadius: '50%',
                  width: isMobile ? '70px' : '90px',
                  height: isMobile ? '70px' : '90px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {product.icon}
              </Box>
              <Typography
                variant="body1"
                color="text.primary"
                sx={{
                  width: '70%',
                  fontSize: { xs: '0.8rem', md: '1rem' },
                }}
              >
                {product.text}
              </Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>
      <RegisterButton
        id="product-body-register"
        label="Regístrate en Fingo"
        variant={isMobile ? 'outlinedPrimary' : 'filledPrimary'}
      />
    </Stack>
  );
}

export default ProductBody;
