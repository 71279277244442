const typography = {
  root: {
    color: 'primary',
  },
  fontFamily: 'Plus Jakarta Sans, sans-serif',
  button: {
    fontSize: 15,
    borderRadius: 15,
    textTransform: 'none',
  },
  body2: {
    fontSize: 14.4,
    '@media (max-width:600px)': {
      fontSize: 9.6,
    },
  },
  body1: {
    '@media (max-width:600px)': {
      fontSize: 11.2,
    },
  },
  body3: {
    fontSize: 16,
    '@media (max-width:600px)': {
      fontSize: 12,
    },
    lineHeight: 1.5,
  },
  subtitle1: {
    fontWeight: 900,
    fontSize: 19.2,
    '@media (max-width:600px)': {
      fontSize: 12.8,
    },
  },
  subtitle2: {
    fontSize: 16,
    '@media (max-width:600px)': {
      fontSize: 11.2,
    },
  },
  subtitle3: {
    fontWeight: 900,
    fontSize: 13,
    '@media (max-width:600px)': {
      fontSize: 12.8,
    },
  },
  subtitle4: {
    fontSize: 13,
    '@media (max-width:600px)': {
      fontSize: 11.2,
    },
  },
  subtitle5: {
    fontSize: 15,
    fontWeight: 900,
  },
  h1: {
    fontSize: 40,
    fontWeight: 'bold',
    '@media (max-width:600px)': {
      fontSize: 20,
    },
    '@media (min-width:600px) and (max-width:1240px)': {
      fontSize: '2vw',
    },
  },
  h2: {
    fontWeight: 'bolder',
    fontSize: 32,
    '@media (max-width:600px)': {
      fontSize: 22.4,
    },
  },
  h3: {
    fontWeight: 'bolder',
    fontSize: 24,
    '@media (max-width:600px)': {
      fontSize: 12.8,
    },
  },
  h4: {
    fontWeight: 'bolder',
    fontSize: 22.4,
    '@media (max-width:600px)': {
      fontSize: 17.6,
      fontWeight: 600,
    },
  },
  h5: {
    fontWeight: 500,
    fontSize: 19.2,
    lineHeight: 1.2,
  },
  caption: {
    fontSize: 12,
    lineHeight: 1.2,
    color: '#ABABAB',
    '@media (max-width:600px)': {
      fontSize: 10,
    },
  },
};

export default typography;
