import React from 'react';
import { Stack, Typography, useTheme, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useQuery } from '@apollo/client';
import BicolorBackgroundBox from '../Utils/boxes/BicolorBackgroundBox';
import useGetCountryInfo from '../../helpers/useGetCountryInfo';
import { TERMS_AND_CONDITIONS } from '../../graphql/queries';

function Terms() {
  const country = useGetCountryInfo();
  const { data } = useQuery(TERMS_AND_CONDITIONS, {
    variables: { countryId: country?.id },
  });
  const termsSections = data?.termsAndConditions?.edges?.flatMap(
    (edge) => edge?.node?.sections,
  );
  const theme = useTheme();

  const termSection = (section) => {
    const { id, text, sectionType } = section;

    if (sectionType === 'subtitle') {
      return (
        <Typography
          paragraph
          sx={{ width: '100%', mt: 2, fontWeight: 'bold', color: theme.palette.primary.main }}
          align="left"
          key={id}
        >
          {text}
        </Typography>
      );
    } if (sectionType === 'table') {
      const validJsonString = text.replace(/([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\s]+)/g, '"$1"');
      const tableData = JSON.parse(validJsonString);
      return (
        <TableContainer component={Paper} sx={{ mt: 2, mb: 2 }} key={id}>
          <Table sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
            <TableHead>
              <TableRow>
                {tableData[0].map((col) => (
                  <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{col}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.slice(1).map((row) => (
                <TableRow>
                  {row.map((cell) => (
                    <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{cell}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
    return (
      <Typography
        paragraph
        sx={{ width: '100%' }}
        align="justify"
        key={id}
      >
        {text}
      </Typography>
    );
  };

  return (
    <BicolorBackgroundBox minHeight={600}>
      <Paper sx={{ minHeight: 500, minWidth: 200, p: 6, borderRadius: 4 }}>
        <Stack direction="column" textAlign="center" width="100%">
          <Typography
            variant="subtitle1"
            sx={{ color: theme.palette.primary.main, mb: 6 }}
          >
            Términos y condiciones de uso de Fingo
          </Typography>
          {termsSections?.map((section) => termSection(section))}
        </Stack>
      </Paper>
    </BicolorBackgroundBox>
  );
}
export default Terms;
