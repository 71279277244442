import { useGetCountry } from '../helpers';

const useSocialMediaLinks = () => {
  const country = useGetCountry();
  const baseLinks = {
    linkedinLink: 'https://www.linkedin.com/company/fingomas/',
    instagramLink: 'https://www.instagram.com/___fingo/',
  };
  const countryLinksMapper = {
    Chile: {
      ...baseLinks,
      mapsLink: 'https://www.google.com/maps/place/Av.+Apoquindo+4660,+Las+Condes,+Regi%C3%B3n+Metropolitana,+Chile/@-33.4124645,-70.5832489,17z/data=!3m1!4b1!4m5!3m4!1s0x9662cf220e058827:0xb5ca3afbfd2b9c82!8m2!3d-33.4124645!4d-70.5810602',
      whatsappLink: 'https://wa.link/2uqivq',
    },
    Mexico: {
      ...baseLinks,
      mapsLink: 'https://maps.app.goo.gl/PSvST7kCtc7bTxcSA',
      whatsappLink: 'https://wa.link/2uqivq', // Still the same, but should change in the near future.
    },
  };
  return countryLinksMapper[country];
};
export default useSocialMediaLinks;
