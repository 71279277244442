import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import CarouselCard from './Card';
import testimonials from './Testimonials';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useIsMobile } from '../../../hooks';

const styles = {
  container: {
    width: '100%',
    background: 'linear-gradient(to bottom, white, #F8EAF1, #C65189 200%)',
    paddingBottom: '10rem',
    '@media (max-width:600px)': {
      marginTop: '2rem',
    },
  },
  carouselContainer: {
    marginTop: '3rem',
    '@media (max-width:600px)': {
      marginTop: 0,
    },
  },
  headerContainer: {
    marginInline: '6rem',
    '@media (max-width:600px)': {
      marginInline: '1.5rem',
    },
  },
};

function CustomersCarousel() {
  const isMobile = useIsMobile('sm');
  const isTablet = useIsMobile('md');

  const getCarouselWidth = () => {
    if (isMobile) {
      return 90;
    }
    if (isTablet) {
      return 50;
    }
    return 28;
  };

  return (
    <Grid sx={styles.container}>
      <Grid sx={styles.headerContainer}>
        <Typography
          variant="h2"
        >
          <Box display={isMobile ? 'inline' : 'block'} color="primary.main">Confían</Box> en nosotros
        </Typography>
        <br />
        <Typography
          variant={isMobile ? 'h3' : 'subtitle2'}
          fontWeight={300}
          maxWidth="30rem"
        >
          Luego de usar nuestros productos,
          algunas personas han expresado sus palabras y testimonios.
        </Typography>
      </Grid>
      <Box
        sx={{
          ...styles.carouselContainer,
        }}
      >
        <Carousel
          centerMode
          interval={2500}
          transitionTime={1000}
          centerSlidePercentage={getCarouselWidth()}
          autoPlay={!isMobile}
          showArrows={!isMobile}
          infiniteLoop
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          slidesToShow={3.5}
          stopOnHover
        >
          {testimonials.map((testimonial) => (
            <CarouselCard
              key={testimonial.title}
              image={testimonial.image}
              title={testimonial.title}
              subtitle={testimonial.subtitle}
              testimonial={testimonial.testimonial}
            />
          ))}
        </Carousel>
      </Box>
    </Grid>
  );
}

export default CustomersCarousel;
