import { Box, Grid } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { TypeAnimation } from 'react-type-animation';
import { useIsMobile } from '../../hooks';

const styles = {
  handsImage: {
    width: '100%',
    opacity: 0.6,
    objectFit: 'cover',
  },
  handsTextContainer: {
    position: 'absolute',
    width: '100%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: 'white',
    zIndex: 1,
  },
  handsText: {
    fontSize: '3.5rem',
    '@media (max-width:600px)': {
      fontSize: '1.3rem',
    },
  },
  container: {
    position: 'relative',
    display: 'flex',
    maxHeight: '35rem',
    background: 'linear-gradient(to top, #000000, #FFFFFF)',
    width: '100%',
    '@media (max-width:600px)': {
      minHeight: '20rem',
      display: 'inline-flex',
      backgroundColor: '#FFFFFF',
    },
  },
};

function SectionPhoto({ photo, text }) {
  const isMobile = useIsMobile();
  return (
    <Grid sx={styles.container}>
      <Grid item sx={styles.handsTextContainer}>
        <Box
          m="auto"
          width={isMobile ? '80%' : '40%'}
        >
          <TypeAnimation
            sequence={[
              text,
              3000,
              '',
              500,
            ]}
            wrapper="div"
            cursor
            speed={40}
            deletionSpeed={75}
            repeat={Infinity}
            style={{
              fontSize: isMobile ? '2.2rem' : '3rem',
              fontFamily: 'Plus Jakarta Sans',
              fontWeight: 900,
            }}
          />
        </Box>
      </Grid>
      <img src={photo} alt="ending_hero" style={styles.handsImage} />
    </Grid>
  );
}

SectionPhoto.propTypes = {
  photo: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default SectionPhoto;
