/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';

export const INITIAL_VALUES = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
};

export const VALIDATION_SCHEMA = Yup.object({
  firstName: Yup.string().required('El nombre es obligatorio'),
  lastName: Yup.string().required('El apellido es obligatorio'),
  phoneNumber: Yup.string()
    .matches(/^\+?\d{9,}$/, 'Número de teléfono inválido')
    .required('El teléfono es obligatorio'),
  email: Yup.string()
    .email('Correo electrónico inválido')
    .required('El correo electrónico es obligatorio'),
});

export const COLLECTION_DEMO_FORM_TITLE_PARTS = [
  { id: 1, text: 'Ingresa tus ', color: 'text' },
  { id: 2, text: 'datos', color: 'secondary' },
  { id: 2, text: ' y te contactaremos para ', color: 'text' },
  { id: 2, text: 'una demo', color: 'secondary' },
];

export const COLLECTION_DEMO_FORM_BODY_PARTS = [
  {
    id: 1,
    text: 'Porque cobrar nunca ha sido tan fácil como será con ',
    color: 'text',
    fontWeight: 'bold',
  },
  { id: 2, text: 'koBros', color: 'secondary', fontWeight: 'bold' },
];
