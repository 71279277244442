import React from 'react';
import MediaBanner from '../Utils/MediaBanner';
import handleUtmRedirect from '../../helpers/handleUtmRedirect';
import getPlatformUrlByCountry from '../../helpers/getPlatformUrlByCountry';
import { useIsMobile } from '../../hooks';
import { getCdnUrl } from '../../helpers';

function VideoBanner() {
  const platformUrl = getPlatformUrlByCountry();
  const isMobile = useIsMobile();
  const title = isMobile ? 'Transformamos la forma en que las empresas interactúan' : 'En Fingo transformamos la forma en que las empresas interactúan';
  const subtitle = 'Controla tu cobranza, finánciate, recibe pagos y maneja tus gastos en un solo lugar de manera simple';
  const buttonLabel = '¡Regístrate ahora!';
  const onClick = () => handleUtmRedirect(`${platformUrl}/register`);
  const src = isMobile
    ? getCdnUrl('/smoke_mobile.mp4')
    : getCdnUrl('/smoke.mp4');
  return (
    <MediaBanner
      title={title}
      subtitle={subtitle}
      src={src}
      buttonLabel={buttonLabel}
      onClick={onClick}
      isVideo
      hasButton={!isMobile}
    />
  );
}

export default VideoBanner;
