import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { esES } from '@mui/material/locale';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import components from './components';

const theme = createTheme({
  palette,
  breakpoints,
  typography,
  components,
  esES,
});

export default responsiveFontSizes(theme);
