import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Paper } from '@mui/material';
import { useGetCountry } from '../../helpers';
import MexicoCostsAndComissionsHelpComponent from './MexicoCompliance/MexicoCostsAndComisionsHelpComponent';
import BicolorBackgroundBox from '../Utils/boxes/BicolorBackgroundBox';
import BuroHelpComponent from './MexicoCompliance/BuroHelpComponent';
import HelpPrivacy from './HelpPrivacy';

function HelpComplianceInfo() {
  const { type } = useParams();
  const navigate = useNavigate();
  const country = useGetCountry();

  const complianceContentMap = {
    Mexico: {
      'buro-info': <BuroHelpComponent />,
      'costs-and-comissions': <MexicoCostsAndComissionsHelpComponent />,
      'notice-of-privacy': <HelpPrivacy />,
    },
  };

  // Check if the country exists in the map
  if (!(country in complianceContentMap)) {
    navigate('/');
    return null;
  }

  // Check if the compliance type exists for the country
  if (!(type in complianceContentMap[country])) {
    navigate('/');
    return null;
  }

  const content = complianceContentMap[country][type];

  return (
    <BicolorBackgroundBox minHeight={600}>
      <Paper sx={{ minHeight: 500, minWidth: 200, p: 6, borderRadius: 4 }}>
        {content}
      </Paper>
    </BicolorBackgroundBox>
  );
}

export default HelpComplianceInfo;
