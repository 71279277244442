import {
  MyPurchasesScreenshot,
  MySalesScreenshot,
  FinancingScreenshot,
} from '../assets/platform/images';

const platformArray = [
  {
    backTitle: 'Mis compras',
    title: 'Mis compras',
    text: [
      'Accede a reporterías y análisis de todas tus facturas recibidas.',
      'Monitorea el estado de tus facturas y controla tus cuentas por pagar.',
      'Comunícarte con tus equipos de abastecimiento y tesorería para mejorar la trazabilidad y entendimiento de problemas.',
      'Comunícate con tus proveedores a través de nuestro chat y darles la oportunidad de financiar sus cuentas por pagar.',
    ],
    image: MyPurchasesScreenshot,
  },
  {
    backTitle: 'Mis ventas',
    title: 'Mis ventas',
    text: [
      'Ten control diario de tus ventas, segmentadas por variables como industria, clientes u otros.',
      'Accede a oportunidades exclusivas de venta en portales de compra de nuestros partners.',
      'Comunícarte con tus clientes para tener información respecto al estado de tus operaciones.',
      'Financia tus cuentas por cobrar y ofrecer condiciones flexibles de pago a tus clientes.',
    ],
    image: MySalesScreenshot,
  },
  {
    backTitle: 'Financiamiento',
    title: 'Financiamiento',
    text: [
      'text supplier factoring',
      'Controla el estado de tus operaciones y lleva seguimiento de la cobranza de tus operaciones.',
      'Genera reportería de la salud financiera y gestión documental de tu empresa para presentar a tus proveedores, clientes y/o instituciones financieras.',
    ],
    image: FinancingScreenshot,
  },
];

export default platformArray;
