import { useQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { GET_COUNTRY_BY_NAME } from '../graphql/queries';
import useGetCountry from './useGetCountry';

const useGetCountryInfo = (onCompleted = () => {}) => {
  const countryName = useMemo(() => useGetCountry(), []);
  const { data, loading, refetch, error } = useQuery(GET_COUNTRY_BY_NAME, {
    variables: { name: countryName },
    onCompleted,
    onError: () => setTimeout(refetch, 1500),
  });
  useEffect(() => {
    if (!error && !loading && !data?.getCountryByName) {
      refetch();
    }
  }, [loading, data]);
  return data?.getCountryByName;
};

export default useGetCountryInfo;
