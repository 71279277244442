import React from 'react';
import { Box, Grid } from '@mui/material';
import { MrKobrosSitting } from '../../assets/kobros';
import CompositeTypography from '../Utils/typography/CompositeTypography';
import { MR_KOBROS_ADVICE } from '../../constants/kobros/kobros-header';

function MrKobrosAdvice() {
  return (
    <Grid
      my={12}
      height={240}
      alignItems="end"
      display={{ xs: 'none', md: 'flex' }}
      justifyContent="center"
      mx="auto"
      bgcolor="background.light"
    >
      <Box
        component="img"
        sx={{
          maxHeight: 200,
          height: 'auto',
          objectFit: 'contain',
          position: 'relative',
          left: -124,
          top: 64,
          zIndex: 2,
        }}
        alt="MrKobros"
        src={MrKobrosSitting}
      />
      <CompositeTypography
        width="64%"
        alignSelf="center"
        textParts={MR_KOBROS_ADVICE}
      />
    </Grid>
  );
}

export default MrKobrosAdvice;
