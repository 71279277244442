import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import KobrosLogoImage from '../../../assets/brand/images/kobros_logo.png';

function KobrosLogo() {
  const navigate = useNavigate();

  return (
    <Box
      component="img"
      justifyContent="flex-start"
      sx={{
        maxHeight: 55,
        maxWidth: 'auto',
        cursor: 'pointer',
        objectFit: 'contain',
      }}
      alt="Kobros"
      src={KobrosLogoImage}
      onClick={() => {
        navigate('/kobros');
      }}
    />
  );
}

export default KobrosLogo;
