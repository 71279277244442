const createAccountVideo = 'https://youtu.be/mXGmHurskJg';
const orderingVideo = 'https://youtu.be/nkPRef7h5OI';
const factoringVideo = {
  Chile: 'https://www.youtube.com/watch?v=a3SQgM8Z6HQ',
  Mexico: 'https://youtu.be/q7MsMS7J600',
};

export {
  createAccountVideo,
  orderingVideo,
  factoringVideo,
};
