import React from 'react';
import { Typography, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logoWithName from '../../../../assets/brand/images/logo_with_name.png';
import {
  mapsLink,
} from '../../../Utils/Links';
import useContactInformation from '../../../../hooks/useContactInformation';
import SocialMediaStack from '../SocialMediaStack';
import ContactText from '../../../Utils/text/ContactText';
import { useIsMobile } from '../../../../hooks';
import MobileFooter from '../MobileFooter';
import { PrimaryLink } from '../../../Utils/link';

const styles = {
  footerContainer: {
    bottom: 0,
    position: 'relative',
    height: '27rem',
    width: '100%',
    display: 'table',
    backgroundColor: '#F8F8F8',
    color: 'text.primary',
    padding: '4rem',
  },
  footerTitle: {},
  spacingGrid: {
    minHeight: '3.9rem',
    justifyContent: 'space-evenly',
  },
  href: {
    color: '#323232',
    textDecoration: 'none',
  },
  logo: {
    width: 130,
  },
  socialMedia: {
    width: 20,
    marginTop: '21%',
  },
};

function FooterChile() {
  const { mail, phoneNumber, address, floor } = useContactInformation();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const contacts = [
    {
      title: 'Comercial',
      phone: phoneNumber,
      email: mail,
    },
    {
      title: 'Cobranza',
      phone: '+56 9 9434 1225',
      email: 'cobranza_ratificacion@fingo.cl',
    },
  ];
  return (

    isMobile
      ? <MobileFooter />
      : (
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            backgroundColor: '#F8F8F8',
            padding: '4rem 15rem',
          }}
        >
          <Typography sx={styles.footerTitle} variant="h3">
            Contáctanos
          </Typography>
          <Stack direction="column" spacing={1.5}>
            {contacts.map((contact) => (
              <ContactText
                title={contact.title}
                textArray={[contact.phone, contact.email]}
                key={contact.title}
                direction="column"
                alignItems="left"
                spacing={1}
              />
            ))}
          </Stack>
          <Stack direction="column" justifyContent="space-between">
            <Stack direction="column" spacing={1}>
              <Typography variant="subtitle3">Dirección</Typography>
              <Typography variant="subtitle4">
                {address}
              </Typography>
              <Typography variant="subtitle4">{floor}</Typography>
              <Typography variant="subtitle4">
                <a
                  href={mapsLink}
                  style={styles.href}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Ver en Maps
                </a>
              </Typography>
            </Stack>
            <Typography variant="subtitle4">
              <PrimaryLink
                rel="noopener noreferrer"
                onClick={() => navigate('/terms')}
                component="button"
                textAlign="left"
              >
                Términos y condiciones
              </PrimaryLink>
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="space-between">
            <SocialMediaStack />
            <a href="/" rel="noopener noreferrer">
              <div>
                <img src={logoWithName} alt="logo" style={styles.logo} />
              </div>
            </a>
          </Stack>
        </Stack>
      )
  );
}
export default FooterChile;
