import { useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { LOGIN, REGISTER } from '../graphql/mutations';
import { getCookie } from '../helpers/cookies';
import { logIn } from '../helpers/auth';
import { openExistingAccountDialogVar } from '../apollo-client/reactive-variables';

const useRegister = (values, onCompleted) => {
  const utmCampaign = getCookie('utm_campaign');

  const [login, { loading: loginLoading }] = useMutation(LOGIN, {
    variables: {
      email: values.email.toLowerCase(),
      password: values.password,
      utmCampaign,
    },
    onCompleted: ({ tokenAuth }) => {
      logIn(tokenAuth);
      onCompleted();
    },
  });

  const [register, { loading: registerLoading }] = useMutation(REGISTER, {
    variables: {
      ...values,
      email: values.email.toLowerCase(),
      phoneNumber: `+56${values.phoneNumber}`,
      utmCampaign,
      origin: 'kobros',
    },
    onCompleted: () => {
      login();
    },
    onError: () => {
      openExistingAccountDialogVar(true);
    },
  });

  const loading = useMemo(
    () => loginLoading || registerLoading,
    [loginLoading, registerLoading],
  );

  return { register, loading };
};

export default useRegister;
