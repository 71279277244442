import { hotjar } from 'react-hotjar';

const HotjarProvider = ({ children }) => {
  const isHotjarInitialized = hotjar.initialized();
  if (!isHotjarInitialized && process.env.NODE_ENV === 'production') {
    hotjar.initialize(2019010, 6);
  }
  return children;
};

export default HotjarProvider;
