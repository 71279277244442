import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography, useTheme } from '@mui/material';
import noticeOfPrivacyFaqs from '../Utils/text/NoticeOfPrivacyFaqs';

function HelpPrivacy() {
  const theme = useTheme();
  return (
    <Stack direction="column" textAlign="center" width="100%">
      <Typography variant="subtitle1" sx={{ color: theme.palette.primary.main, mb: 8 }}>
        Aviso de privacidad
      </Typography>
      <Typography variant="body2" sx={{ mb: 6, px: 8, textAlign: 'left', alignSelf: 'center' }}>
        Fingo Tech Capital, S.A.P.I. de C.V., SOFOM E.N.R, mejor conocido como Fingo,
        con domicilio en calle Varsovia número 36, colonia Juárez, ciudad Ciudad de México,
        municipio o delegación Cuauhtémoc, c.p. 06600, en la entidad de Ciudad de México,
        país México, y portal de internet www.fingo.com.mx, es el responsable del uso y
        protección de sus datos personales, y al respecto le informamos lo siguiente:
      </Typography>
      {noticeOfPrivacyFaqs.map((faq) => (
        <Accordion key={faq.title} elevation={0} sx={{ width: '65%', alignSelf: 'center' }}>
          <AccordionSummary
            expandIcon={<Typography color="primary" variant="h3">+</Typography>}
            aria-controls={`panel-${faq.title}-content`}
            id={`panel-${faq.title}-header`}
            sx={{ border: 0 }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>{faq.title}</Typography>
          </AccordionSummary>
          {faq.paragraphs.map((paragraph) => (
            <AccordionDetails sx={{ justifyContent: 'flex-start', alignItems: 'flex-start', textAlign: 'left', padding: '7px' }}>
              {paragraph}
            </AccordionDetails>
          ))}
        </Accordion>
      ))}
    </Stack>
  );
}

export default HelpPrivacy;
