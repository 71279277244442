import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import KobrosNavBar from './KobrosNavbar';
import DefaultNavbar from './DefaultNavbar';

function Navbar() {
  const { pathname } = useLocation();
  const isKobros = useMemo(() => pathname.includes('kobros'), [pathname]);
  if (isKobros) return <KobrosNavBar />;
  return <DefaultNavbar />;
}

export default Navbar;
