import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

function ShadowText({ children, color, opacity, fontSize, ...props }) {
  return (
    <Typography
      sx={{
        fontFamily: 'Plus Jakarta Sans',
        opacity,
        fontWeight: 900,
        position: 'relative',
        fontSize,
        top: '40px',
        overflow: 'hidden',
        zIndex: '-1',
        '@media (max-width: 900px)': {
          display: 'none',
        },
      }}
      color={color}
      {...props}
    >
      {children}
    </Typography>
  );
}

ShadowText.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  opacity: PropTypes.number,
  fontSize: PropTypes.string,
};

ShadowText.defaultProps = {
  color: 'primary.main',
  opacity: 0.5,
  fontSize: '4rem',
};

export default ShadowText;
