import { Grid } from '@mui/material';
import React from 'react';
import HandPhoto from '../Home/HandPhoto';
import OurPlatformHeader from './OurPlatformHeader';
import PlatformBody from './PlatformBody';

const styles = {
  container: {
    overflow: 'hidden',
  },
};

function Platform() {
  return (
    <Grid
      direction="column"
      justifyContent="flex-end"
      alignItems="center"
      container
      sx={styles.container}
      spacing={0}
    >
      <OurPlatformHeader />
      <PlatformBody />
      <HandPhoto />
    </Grid>
  );
}

export default Platform;
