import React, { useState } from 'react';
import { Button, Grid, useMediaQuery } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import DesktopNavbar from './DesktopNavbar';
import MobileNavbar from './MobileNavbar';
import getPlatformUrlByCountry from '../../../helpers/getPlatformUrlByCountry';
import kobrosLogo from '../../../assets/brand/images/kobros_logo.png';
import CollectionDemoCalendar from '../../Kobros/dialogs/CollectionDemoCalendar';

const pages = [
  {
    title: 'Solicita Información',
    url: 'home-solutions',
    id: 'home-solutions',
    scroll: true,
  },
  {
    title: 'Features',
    url: 'home-features',
    id: 'home-features',
    scroll: true,
  },
  {
    title: 'FAQ',
    url: 'home-faq',
    id: 'home-faq',
    scroll: true,
  },
];

function KobrosLogo() {
  const navigate = useNavigate();
  return (
    <Grid onClick={() => navigate('/kobros')} width={120} sx={{ cursor: 'pointer' }}>
      <img src={kobrosLogo} alt="logo" width="100%" />
    </Grid>
  );
}

function KobrosNavBar() {
  const isMobile = useMediaQuery('(max-width: 920px)');
  const navigate = useNavigate();
  const platformUrl = getPlatformUrlByCountry();
  const [openCalendar, setOpenCalendar] = useState(false);
  const buttons = [
    {
      title: 'Explora kobros',
      url: `${platformUrl}/collection-register`,
      variant: 'contained',
      id: 'login-mobile',
      color: 'secondary',
      queryParams: { loginType: 'signin' },
    },
    {
      title: 'Solicitar demo',
      url: () => setOpenCalendar(true),
      variant: 'outlined',
      id: 'register-mobile',
      color: 'secondary',
      queryParams: { loginType: 'signup' },
    },
  ];
  return (
    <>
      {
      isMobile
        ? (
          <MobileNavbar
            buttons={buttons}
            pages={pages}
            Logo={KobrosLogo}
          />
        )
        : (
          <DesktopNavbar
            buttons={buttons}
            pages={pages}
            Logo={KobrosLogo}
          />
        )
      }
      <CollectionDemoCalendar
        open={openCalendar}
        onClose={() => setOpenCalendar(false)}
        path=""
      />
      <Button sx={{ ml: 2, mt: 2, position: 'absolute', zIndex: 1500 }} color="primary" size="large" onClick={() => navigate('/')}>
        <ArrowBack sx={{ mr: 1 }} />
        Ir a fingo
      </Button>
    </>
  );
}

export default KobrosNavBar;
