import React from 'react';
import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import platformArray from '../../constants/our-platform-body-content';
import { DotList } from '../Utils/lists';
import { RegisterButton } from '../Utils/buttons';
import { useIsMobile } from '../../hooks';
import { ShadowText } from '../Utils/typography';

function PlatformBody() {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const getStackDirection = (index) => {
    if (isMobile) {
      return 'column-reverse';
    }
    if (index % 2 === 0) {
      return 'row-reverse';
    }
    return 'row';
  };
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        width: isMobile ? '90%' : '100%',
        marginBottom: '3.5rem',
      }}
    >
      <Grid
        container
        justifyContent="center"
        rowGap={10}
        sx={{
          width: '100%',
          margin: '-4rem 0 4rem 0',
          padding: '0 6%',
          '@media (max-width: 1150px)': {
            margin: '0 0 3rem 0',
            padding: '0 0.5rem',
          },
          '@media (max-width: 900px)': {
            margin: '4rem 0 3rem 0',
          },
        }}
      >
        {platformArray.map((item, index) => (
          <Stack
            key={item.title}
            direction={getStackDirection(index)}
            justifyContent="center"
            alignItems="flex-start"
            spacing={isMobile ? -5 : 6}
          >
            <Card
              elevation={isMobile ? 0 : 5}
              sx={{
                width: isMobile ? '100%' : '35%',
                padding: isMobile ? 0 : '3%',
                borderRadius: isMobile ? 0 : '0.8rem',
                marginTop: '4rem',
                display: 'flex',
                mx: isMobile ? 'auto' : 0,
              }}
            >
              <img
                src={item.image}
                alt={item.title}
                style={{
                  width: '100%',
                }}
              />
            </Card>
            <Stack
              direction="column"
              spacing={2.5}
              width={isMobile ? '100%' : '44%'}
            >
              <Box>
                <ShadowText color="secondary.main" opacity={0.1} fontSize="5.5rem">
                  {item.backTitle}
                </ShadowText>
                <Typography
                  variant="h3"
                  color="secondary"
                  sx={{
                    fontSize: { xs: '1.2rem', md: '1.5rem' },
                  }}
                >
                  {item.title}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  maxWidth: '550px',
                }}
              >
                <DotList
                  items={item.text.map((text) => t(text))}
                  stackProps={{ spacing: isMobile ? 0.8 : 1.5 }}
                  itemProps={{ spacing: 2 }}
                  dotProps={{ sx: { fontSize: 8 } }}
                  typographyProps={{ fontSize: { xs: '0.8rem', md: '1rem' } }}
                />
              </Box>
            </Stack>
          </Stack>
        ))}
      </Grid>
      <RegisterButton
        id="platform-body-register"
        label="Regístrate en Fingo"
        variant={isMobile ? 'outlinedSecondary' : 'filledSecondary'}
      />
    </Stack>
  );
}

export default PlatformBody;
