/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import { setVariable } from './local-storage';

export const logIn = ({ token, refreshToken, payload }) => {
  setVariable('token', token);
  setVariable('refreshToken', refreshToken);
  if (payload) {
    const delayOfExpire = 60;
    const difference = payload.exp - payload.origIat;
    const expires = moment().unix() + difference - delayOfExpire;
    setVariable('tokenExpire', expires);
  }
};
