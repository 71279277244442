import {
  Testimonial1,
  Testimonial2,
  Testimonial3,
  Testimonial4,
  Testimonial5,
  Testimonial6,
  Testimonial7,
} from '../../../assets/home/images/testimonials';

const testimonials = [
  {
    image: Testimonial1,
    title: 'SERVICIOS DE INGENIERÍA EMPRESA INSTALACIONES RED DE GAS',
    subtitle: '',
    testimonial: 'Gracias me han hecho mucho más rápido mis solvencia. Son super eficientes, se pasaron.',
  },
  {
    image: Testimonial2,
    title: 'EMPRESA OSVALDO RAMÓN HIDALGO G.',
    subtitle: '',
    testimonial: 'Como empresa nos sentimos muy agradecidos por la amabilidad, rapidez y eficacia de Fingo y sus ejecutivos.',
  },
  {
    image: Testimonial3,
    title: 'FORESTAL BERNARDO CÁRCAMO',
    subtitle: '',
    testimonial: 'Quiero agradecer la amabilidad y rapidez que tuvo para entregarme los servicios de factoring de su empresa.',
  },
  {
    image: Testimonial4,
    title: 'EBQ SOFTWARE SPA',
    subtitle: '',
    testimonial: 'El apoyo y guía que me haentregado mi ejecutiva de FINGO ha sido fenomenal, un excelente tiempo derespuesta en todo ámbito',
  },
  {
    image: Testimonial5,
    title: 'ESTUDIO 203',
    subtitle: '',
    testimonial: 'Rápido tiempo de respuesta, súper claro y transparente en la comunicación, una plataforma fácil e intuitiva de operar. Sin duda, recomiendo FINGO.',
  },
  {
    image: Testimonial6,
    title: 'COMERCIALIZADORA AQUARIOS SPA',
    subtitle: '',
    testimonial: '...es primera vez que ocupo un servicio de factoring y ha sido maravilloso. Me ha ayudado en fortalecer mis ventas y aumentar clientes.',
  },
  {
    image: Testimonial7,
    title: 'MAESTRANZA BRIAMAQ',
    subtitle: '',
    testimonial: 'Quisiera felicitarlos  por sus servicio desde el primer día; una gestión oportuna, donde la información y la ayuda en el proceso es sin duda gratificante.',
  },
];

export default testimonials;
