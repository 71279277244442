import { Fade, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { getCdnUrl } from '../../helpers';
import { useIsMobile } from '../../hooks';

const styles = {
  media: {
    width: '100%',
    position: 'absolute',
    bottom: '0px',
    zIndex: 0,
  },
  mediaMobile: {
    position: 'relative',
    height: '100%',
  },
  mediaContainer: {
    position: 'sticky',
    width: '100%',
    height: '50vw',
    maxHeight: '30rem',
    overflow: 'hidden',
    '@media (max-width:600px)': {
      height: '60vw',
    },
  },
  container: {
    position: 'relative',
    width: '100%',
    height: '50vw',
    maxHeight: '27rem',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width:600px)': {
      height: '60vw',
    },
  },
  textContainer: {
    position: 'absolute',
    top: '50%',
    left: '35%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    zIndex: 1,
    '@media (max-width:600px)': {
      left: '50%',
      transform: 'translate(-50%, -120%)',
      minWidth: '12rem',
    },
  },
  titleText: {
    textAlign: '-webkit-left',
    '@media (max-width:600px)': {
      fontSize: '1.5rem',
      textAlign: '-webkit-center',
      color: 'black',
    },
  },
  subtitleText: {
    textAlign: '-webkit-left',
    marginTop: '0.5rem',
    '@media (max-width:600px)': {
      fontSize: '0.6rem',
      textAlign: '-webkit-center',
      color: 'black',
    },
  },
};

function HelpBanner() {
  const isMobile = useIsMobile('sm');
  const [showImg, setShowImg] = useState(false);
  const title = 'Centro de ayuda';
  const subtitle = 'Todo lo que necesitas saber sobre nuestros productos y servicios';
  const src = isMobile ? getCdnUrl('/images/help_people_mobile.jpeg') : getCdnUrl('/images/help_people.jpeg');
  return (
    <Grid container sx={styles.container} direction="row">
      <Grid item sx={styles.textContainer} xs={6}>
        <Typography sx={styles.titleText} variant="h1">
          {title}
        </Typography>
        <Typography sx={styles.subtitleText} variant="subtitle1">
          {subtitle}
        </Typography>
      </Grid>
      <Grid item xs={6} />
      <Fade
        in={showImg}
        timeout={1000}
      >
        <Grid item sx={styles.mediaContainer}>
          {!isMobile && (<div style={{ background: 'linear-gradient(to right, hsl(0deg 0% 0% / 50%) 10%, #00000054 50%, rgb(0 0 0 / 0%) 68%)', zIndex: 1, position: 'absolute', width: '100%', height: '100%' }} />)}
          <img src={src} alt="banner_media" style={isMobile ? styles.mediaMobile : styles.media} onLoad={() => setShowImg(true)} />
        </Grid>
      </Fade>
    </Grid>
  );
}

export default HelpBanner;
