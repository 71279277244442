import React from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';
import { InputAdornment, TextField } from '@mui/material';

function InputField({
  name,
  label,
  color,
  type,
  startAdornment,
  endAdornment,
  onChange,
  ...props
}) {
  const { errors, touched, getFieldProps } = useFormikContext();

  const fieldError = touched[name] && Boolean(errors[name]);
  const helperText = fieldError ? errors[name] : ' ';

  return (
    <Field
      component={TextField}
      name={name}
      label={label}
      type={type}
      color={color}
      error={fieldError}
      helperText={helperText}
      InputProps={{
        ...(startAdornment && {
          startAdornment: (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ),
        }),
        ...(endAdornment && {
          endAdornment,
        }),
        ...getFieldProps(name),
        ...(onChange !== null && {
          onChange: (event) => {
            onChange(event);
          },
        }),
        sx: { borderRadius: 20 },
      }}
      FormHelperTextProps={{
        sx: { fontSize: { xs: 6, md: 12 } },
      }}
      size="small"
      {...props}
    />
  );
}

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  type: PropTypes.string,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  onChange: PropTypes.func,
};

InputField.defaultProps = {
  color: 'primary',
  type: 'text',
  startAdornment: null,
  endAdornment: null,
  onChange: null,
};

export default InputField;
