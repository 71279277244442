export const personalDataDisclaimer = `Fingo almacena datos personales encriptados con altos 
niveles de seguridad y privacidad.`;

export const contactDisclaimer = `Puede contactar a la CONDUSEF en su página electrónica 
condusef.gob.mx, en el teléfono 55 5340 0999, o en el correo: asesoria@condusef.gob.mx.`;

export const governmentResponsibilityDisclaimer = `Ni el Gobierno Federal ni las entidades de la administración pública 
paraestatal podrán responsabilizarse o garantizar los recursos de los Clientes que sean 
utilizados en las Operaciones que celebren con las ITF o frente a otros, así como tampoco asumir 
alguna responsabilidad por las obligaciones contraídas por las ITF o por algún Cliente frente a 
otro, en virtud de las Operaciones que celebren.`;

export const fintechOperationsDisclaimer = `FINGO TECH CAPITAL SAPI DE CV SOFOM E.N.R. hace del conocimiento de sus 
clientes y del público en general que es una Fintech que oferta exclusivamente productos de 
Factoraje. Para todos los efectos legales, un eventual uso por parte de Fingo, sus funcionarios, 
representantes, empleados o colaboradores de terminología como “crédito”, “financiamiento”, 
“línea de crédito” y en general referencias análogas o similares; hace referencia exclusivamente 
al Factoraje que oferta, siempre en el entendido de que dichos términos se pueden llegar a 
manejar coloquialmente por uso o costumbre, que por tanto no implican dolo o pretenden inducir 
a engaño alguno; así como que de ninguna forma y por ningún motivo comprenden o significan 
referencia a, u oferta de productos financieros distintos al Factoraje de Fingo.`;
