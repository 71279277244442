import Cookies from 'universal-cookie';

const cookies = new Cookies();

const handleUtmRedirect = (url, extraParams = {}) => {
  const session = cookies.get('session');
  const utmCampaign = cookies.get('utm_campaign');
  const queryParams = new URLSearchParams();

  if (utmCampaign) {
    queryParams.set('utm_campaign', utmCampaign);
  }
  queryParams.set('session', session);
  Object.entries(extraParams).forEach(([key, value]) => {
    queryParams.set(key, value);
  });
  const finalUrl = `${url}?${queryParams.toString()}`;
  window.open(finalUrl);
};

export default handleUtmRedirect;
