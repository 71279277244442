import React from 'react';
import FooterChile from './CountryFooters/FooterChile';
import FooterMexico from './CountryFooters/FooterMexico';
import { useGetCountry } from '../../../helpers';

function Footer() {
  const country = useGetCountry();
  const countryComponentMapper = {
    Chile: <FooterChile />,
    Mexico: <FooterMexico />,
  };
  return country && countryComponentMapper[country];
}
export default Footer;
