import React from 'react';
import { useReactiveVar } from '@apollo/client';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MobileStepper,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import { openCollectionRegisterDialogVar } from '../../../apollo-client/reactive-variables';
import CompositeTypography from '../../Utils/typography/CompositeTypography';
import useRegisterSteps from '../../../hooks/useRegisterSteps';

function CollectionRegisterDialog() {
  const openCollectionRegisterDialog = useReactiveVar(
    openCollectionRegisterDialogVar,
  );
  const { steps, activeStep } = useRegisterSteps();

  return (
    openCollectionRegisterDialog && (
      <Dialog
        open={openCollectionRegisterDialog}
        onClose={() => openCollectionRegisterDialogVar(false)}
        PaperProps={{ sx: { p: 5, borderRadius: 5 } }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <CompositeTypography
            textParts={[
              { id: 1, text: 'Regístrate en ', color: 'text' },
              { id: 2, text: 'koBros', color: 'secondary' },
            ]}
            variant="h2"
            textAlign="center"
          />
          <IconButton
            aria-label="close"
            onClick={() => openCollectionRegisterDialogVar(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <MobileStepper
            steps={steps.length}
            activeStep={activeStep}
            variant="dots"
            position="static"
            sx={{
              '.MuiMobileStepper-dotActive': {
                backgroundColor: 'secondary.main',
              },
            }}
          />
          {steps[activeStep].component}
        </DialogContent>
      </Dialog>
    )
  );
}

export default CollectionRegisterDialog;
