import { Button, Grid, Typography, Skeleton, Fade, Box, Stack } from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { whatsappLink } from './Links';
import { WhatsappIcon } from '../../assets/socialMedia/images/whatsapp';

const styles = {
  media: {
    width: '100%',
    objectFit: 'cover',
  },
  textContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: 'white',
    zIndex: 1,
  },
  container: {
    position: 'relative',
    width: '100%',
    height: '30rem',
    overflow: 'hidden',
    marginBottom: '2rem',
    '@media (max-width:600px)': {
      height: '12.5rem',
    },
  },
  titleText: {
    textShadow: '3px 3px 1.5rem #A7A7A7',
    '@media (max-width:600px)': {
      fontSize: '1.5rem',
    },
  },
  subtitleText: {
    marginTop: '1rem',
    '@media (max-width:600px)': {
      fontSize: '0.9rem',
    },
  },
  registerButton: {
    fontWeight: '600',
    border: '1px solid white',
    py: '1.3rem',
    px: '1.5rem',
    boxShadow: 'none',
    '&:hover': {
      color: 'primary.main',
      backgroundColor: 'white',
    },
  },
};

function MediaBanner({ title, subtitle, src, isVideo, hasButton, buttonLabel, onClick }) {
  const [started, setStarted] = useState(false);
  const handleWhatsappClick = () => {
    const newWindow = window.open();
    newWindow.opener = null;
    newWindow.location.href = whatsappLink;
  };
  return (
    <Grid container sx={styles.container} direction="row">
      <Fade
        in
        timeout={500}
      >
        <Grid
          item
          sx={{
            ...styles.textContainer,
            width: { xs: '70%', md: '50%', xl: '35%' },
          }}
        >
          <Typography sx={{ ...styles.titleText, color: started ? '' : '#2E2E2E' }} variant="h1">
            {title}
          </Typography>
          <Typography sx={{ ...styles.subtitleText, color: started ? '' : 'black' }} variant="subtitle1">
            {subtitle}
          </Typography>
          {hasButton && (
            <Stack direction="row" justifyContent="center" spacing={2} mt="2rem">
              <Button
                sx={{
                  ...styles.registerButton,
                  backgroundColor: started ? 'white' : 'primary',
                  color: started ? 'primary' : 'white',
                }}
                onClick={onClick}
                size="large"
                id="in-video-register-desktop"
              >
                {buttonLabel}
              </Button>
              <Button
                sx={{
                  ...styles.registerButton,
                  backgroundColor: started ? 'transparent' : 'primary',
                  color: 'white',
                }}
                onClick={handleWhatsappClick}
                size="large"
                id="in-video-whatsapp-desktop"
                endIcon={<WhatsappIcon sx={{ fontSize: 8 }} />}
              >
                Contáctanos
              </Button>
            </Stack>
          )}
        </Grid>
      </Fade>
      <Grid item xs={6} />
      {isVideo ? (
        <>
          <Fade
            in={started}
            timeout={2000}
          >
            <Box sx={{ position: 'absolute', width: '100%' }}>
              <video autoPlay muted playsInline loop width="100%" onCanPlay={() => setStarted(true)}>
                <source src={src} type="video/mp4" />
              </video>
            </Box>
          </Fade>
          <Fade
            in={!started}
            timeout={2000}
          >
            <Skeleton
              variant="rectangular"
              width="100%"
              height="100%"
              animation="wave"
              sx={{ ...styles.media, background: 'white' }}
            />
          </Fade>
        </>
      ) : (
        <img src={src} alt="banner_media" style={styles.media} />
      )}
    </Grid>
  );
}

MediaBanner.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  isVideo: PropTypes.bool,
  hasButton: PropTypes.bool,
  buttonLabel: PropTypes.string,
  onClick: PropTypes.func,
};
MediaBanner.defaultProps = {
  isVideo: false,
  hasButton: false,
  buttonLabel: null,
  onClick: null,
};

export default MediaBanner;
