import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography, useTheme } from '@mui/material';
import costAndComissionFAQs from '../../Utils/text/CostAndComissionFaqs';

function MexicoCostsAndComissionsHelpComponent() {
  const theme = useTheme();
  return (
    <Stack direction="column" textAlign="center" width="100%">
      <Typography variant="subtitle1" sx={{ color: theme.palette.primary.main, mb: 8 }}>
        Costos y comisiones de Fingo
      </Typography>
      {costAndComissionFAQs.map((faq) => (
        <Accordion key={faq.title} elevation={0} sx={{ width: '65%', alignSelf: 'center' }}>
          <AccordionSummary
            expandIcon={<Typography color="primary" variant="h3">+</Typography>}
            aria-controls={`panel-${faq.title}-content`}
            id={`panel-${faq.title}-header`}
            sx={{ border: 0 }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>{faq.title}</Typography>
          </AccordionSummary>
          {faq.paragraphs.map((paragraph) => (
            <AccordionDetails sx={{ justifyContent: 'flex-start', alignItems: 'flex-start', textAlign: 'left', padding: '7px' }}>
              {paragraph}
            </AccordionDetails>
          ))}
        </Accordion>
      ))}
    </Stack>
  );
}

export default MexicoCostsAndComissionsHelpComponent;
