import { ArrowRightAlt } from '@mui/icons-material';
import { Box, Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router-dom';

function ProductCard({
  title,
  titleColor,
  redirectTo,
  image,
}) {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        maxWidth: '90%',
        cursor: 'pointer',
        margin: '1rem',
        textAlignLast: 'center',
        borderRadius: '0.7rem',
        '&:hover': {
          backgroundColor: 'background.main',
        },
      }}
      onClick={() => { if (redirectTo) navigate(redirectTo); }}
      elevation={5}
    >
      <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Box>
          <img
            src={image}
            alt={title}
            style={{
              width: '80%',
              marginInline: '10%',
            }}
          />
        </Box>
        <Typography
          color={titleColor}
          variant="h3"
          textAlign="center"
          sx={{
            fontSize: '1.2rem !important',
            '@media (max-width:600px)': {
              fontSize: '0.7rem !important',
            },
          }}
        >
          {title}
          <ArrowRightAlt sx={{ mb: -0.8 }} />
        </Typography>
      </CardContent>
    </Card>
  );
}

ProductCard.propTypes = {
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string,
  redirectTo: PropTypes.string,
  image: PropTypes.string.isRequired,
};

ProductCard.defaultProps = {
  titleColor: 'primary',
  redirectTo: '',
};

export default ProductCard;
