import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme,
} from '@mui/material';
import { formatText } from '../../../helpers';

function KobrosAccordionItem(question) {
  const theme = useTheme();
  const { id, title, paragraphs } = question;
  return (
    <Accordion key={id} elevation={0}>
      <AccordionSummary
        expandIcon={(
          <Typography color="secondary" fontSize={{ xs: 20, md: 35 }}>
            +
          </Typography>
        )}
      >
        <Typography
          variant={{ xs: 'h3', md: 'subtitle2' }}
          fontSize={{ xs: 14, md: 16 }}
          pr={5}
        >
          {formatText(title, theme.palette.secondary.dark)}
        </Typography>
      </AccordionSummary>
      {paragraphs.map((paragraph) => (
        <AccordionDetails key={paragraph}>
          <Typography variant="body2" fontSize={{ xs: '0.8rem', md: '0.9rem' }}>
            {formatText(paragraph, theme.palette.secondary.dark)}
          </Typography>
        </AccordionDetails>
      ))}
    </Accordion>
  );
}

KobrosAccordionItem.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    paragraphs: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default KobrosAccordionItem;
