import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

function SecretButton({ onClick }) {
  const [fPressed, setFPressed] = useState(false);
  const [iPressed, setIPressed] = useState(false);
  const [nPressed, setNPressed] = useState(false);
  const [gPressed, setGPressed] = useState(false);
  const [oPressed, setOPressed] = useState(false);

  const handleClick = () => {
    if (fPressed && iPressed && nPressed && gPressed && oPressed) {
      onClick();
    }
  };

  const handleKeyDown = (event) => {
    const key = event.key.toLowerCase();
    if (key === 'f') {
      setFPressed(true);
    } else if (key === 'i') {
      setIPressed(true);
    } else if (key === 'n') {
      setNPressed(true);
    } else if (key === 'g') {
      setGPressed(true);
    } else if (key === 'o') {
      setOPressed(true);
    }
  };

  const handleKeyUp = (event) => {
    const key = event.key.toLowerCase();
    if (key === 'f') {
      setFPressed(false);
    } else if (key === 'i') {
      setIPressed(false);
    } else if (key === 'n') {
      setNPressed(false);
    } else if (key === 'g') {
      setGPressed(false);
    } else if (key === 'o') {
      setOPressed(false);
    }
  };
  return (
    <Button
      id="team-secret-button"
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      sx={{
        height: '0px',
        width: '0px',
        padding: '1px',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      }}
    />
  );
}

SecretButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SecretButton;
