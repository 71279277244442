const palette = {
  primary: {
    main: '#ED265B',
    light: '#FFE5EE',
    unreadNotification: '#FDEEF3',
  },
  secondary: {
    main: '#6F28A9',
    dark: '#4D1060',
  },
  secondarynt: {
    main: '#F5F5F5',
    contrastText: '#6F28A9',
  },
  text: {
    main: '#323232',
    primary: '#323232',
    secondary: '#ABABAB',
  },
  chart: {
    primary: '#ED265B',
    secondary: '#999999',
    lightPinkColor: '#e62e6629',
  },
  background: {
    main: '#F5F5F5',
    mild: '#f2f2f2',
    light: '#F8F8F8',
  },
};

export default palette;
