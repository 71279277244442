/* eslint-disable import/prefer-default-export */
export const getScoreProperties = (score) => {
  switch (score) {
    case 0:
      return { color: 'error.main', text: 'Muy baja' };
    case 1:
      return { color: 'error.light', text: 'Baja' };
    case 2:
      return { color: 'warning.main', text: 'Media' };
    case 3:
      return { color: 'success.light', text: 'Alta' };
    case 4:
      return { color: 'success.main', text: 'Excelente' };
    default:
      return null;
  }
};
