import React from 'react';
import { Typography, Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logoWithName from '../../../assets/brand/images/logo_with_name.png';
import {
  mapsLink,
  instagramLink,
  linkedinLink,
} from '../../Utils/Links';
import linkedin from '../../../assets/socialMedia/images/linkedin.png';
import instagram from '../../../assets/socialMedia/images/instagram.png';
import useContactInformation from '../../../hooks/useContactInformation';
import { PrimaryLink } from '../../Utils/link';

const style = {
  logo: {
    width: 100,
  },
  href: {
    color: '#323232',
    textDecoration: 'none',
  },
  socialMedia: {
    width: '1.2rem',
    marginTop: '25%',
  },
};

function MobileFooter() {
  const navigate = useNavigate();
  const { mail, phoneNumber, address } = useContactInformation();
  return (
    <Stack
      direction="column"
      spacing={1.5}
      sx={{
        backgroundColor: '#F8F8F8',
        color: 'text.primary',
        padding: '2rem',
      }}
    >
      <Typography variant="h2">Contáctanos</Typography>
      <Stack direction="column" spacing={1} sx={{ paddingBottom: 1 }}>
        <Stack direction="column" spacing={0.2}>
          <Typography variant="subtitle1">Comercial</Typography>
          <Typography variant="subtitle2">
            {phoneNumber} / {mail}
          </Typography>
        </Stack>
        <Stack direction="column" spacing={0.2}>
          <Typography variant="subtitle1">Cobranza</Typography>
          <Typography variant="subtitle2">
            +56 9 9434 1225 / cobranza_ratificacion@fingo.cl
          </Typography>
        </Stack>
        <Stack direction="column" spacing={0.2}>
          <Typography variant="subtitle1">Dirección</Typography>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="subtitle2">{address}</Typography>
            <Typography variant="subtitle2" fontWeight={600}>
              <a
                href={mapsLink}
                style={style.href}
                rel="noopener noreferrer"
                target="_blank"
              >
                Ver en Maps
              </a>
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Typography variant="subtitle1">
        <PrimaryLink
          rel="noopener noreferrer"
          onClick={() => navigate('/terms')}
          component="button"
          textAlign="left"
        >
          Términos y condiciones
        </PrimaryLink>
      </Typography>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="subtitle1">¡Síguenos!</Typography>
          <Box>
            <a href={linkedinLink} rel="noopener noreferrer">
              <img src={linkedin} alt="logo" style={style.socialMedia} />
            </a>
          </Box>
          <Box>
            <a href={instagramLink} rel="noopener noreferrer">
              <img src={instagram} alt="logo" style={style.socialMedia} />
            </a>
          </Box>
        </Stack>
        <Box>
          <a href="/" rel="noopener noreferrer">
            <div>
              <img src={logoWithName} alt="logo" style={style.logo} />
            </div>
          </a>
        </Box>
      </Stack>
    </Stack>
  );
}

export default MobileFooter;
