import { Typography, Grid, Stack, Box } from '@mui/material';
import React, { useRef } from 'react';
import Flippy, { BackSide, FrontSide } from 'react-flippy';
import PictureGrid from './PicturesGrid';
import { Bag, Mountain, Glasses } from '../../assets/team/icons';
import { useIsMobile } from '../../hooks';
import { SecretButton } from '../Utils/buttons';
import { getCdnUrl } from '../../helpers';
import useContactInformation from '../../hooks/useContactInformation';

const styles = {
  container: {
    minHeight: '10rem',
    '@media (max-width:600px)': {
      background: 'none',
    },
  },
  title: {
    textAlign: '-webkit-center',
    marginTop: '3rem',
    '@media (max-width:600px)': {
      marginTop: '2rem',
    },
  },
  subtitle: {
    marginTop: '1.5rem',
    width: '40%',
    '@media (max-width:600px)': {
      marginTop: '1.2rem',
      width: '90%',
      fontSize: '0.8rem',
    },
  },
  textContainer: {
    maxWidth: '80%',
    marginInline: 'auto',
    marginBottom: '2rem',
  },
  textItem: {
    marginTop: '3rem',
  },
  teamPicture: {
    width: '100%',
  },
  pictureContainer: {
    marginTop: '2rem',
    marginBottom: '-1rem',
    marginInline: 'auto',
  },
  href: {
    color: 'primary.main',
  },
  icon: {
    verticalAlign: 'middle',
    marginLeft: '0.5rem',
  },
  pictureGrid: {
    minHeight: '15rem',
  },
};

function Team() {
  const isMobile = useIsMobile('sm');
  const { mail } = useContactInformation();
  const src = isMobile ? '/team/team_mobile.png' : '/team/team.png';
  const cardRef = useRef(null);
  const flipCard = () => {
    cardRef.current.toggle();
  };
  const paragraphs = [
    {
      title: 'Nuestra visión',
      icon: Glasses,
      text: 'Queremos ser la red entre pequeñas, medianas y grandes empresas más grande con mayor eficiencia en el manejo de dinero de Latinoamérica.',
      mdSize: 6,
      maxWidth: '80%',
    },
    {
      title: 'Nuestra misión',
      icon: Mountain,
      text: 'Buscamos transformar la forma en que las empresas interactúan y simplificar procesos con el fin de disminuir los tiempos de pago y mejorar el acceso a financiamiento.',
      mdSize: 6,
      maxWidth: '80%',
    },
    {
      title: 'Trabaja en Fingo',
      icon: Bag,
      text: '¿Te gustaría pertenecer a este gran producto y ser parte de nuestro equipo de trabajo? Revisa las ofertas de trabajo o escríbenos a',
      mdSize: 12,
      maxWidth: '100%',
      mailTo: (
        <a href={`mailto:${mail}`} style={styles.href}>
          {mail}
        </a>
      ),
    },
  ];
  const realPhoto = (
    <Grid
      item
      sx={{
        ...styles.pictureContainer,
        minHeight: isMobile ? '7rem' : '20rem',
      }}
    >
      <img src={getCdnUrl(src)} alt="team" style={styles.teamPicture} />
    </Grid>
  );
  const secretPhoto = (
    <Box
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <img
        src={getCdnUrl('/team/fongo.png')}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
        alt="secret_team"
      />
    </Box>
  );
  return (
    <Grid container sx={styles.container}>
      <Grid container direction="column">
        <Grid item sx={styles.title}>
          <Typography variant="h1" textAlign="center" color="primary">
            Nosotros
          </Typography>
          <Typography
            variant="subtitle2"
            textAlign="center"
            sx={styles.subtitle}
          >
            Hemos construido un equipo profesional, dedicado y apasionado por
            brindarle el mejor servicio a las personas
          </Typography>
        </Grid>
        <Grid item sx={styles.pictureGrid}>
          <PictureGrid />
        </Grid>
      </Grid>
      <Grid container direction="row" sx={styles.textContainer}>
        {paragraphs.map((paragraph) => (
          <Grid
            item
            key={paragraph.title}
            md={paragraph.mdSize}
            xs={12}
            sx={styles.textItem}
          >
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={isMobile ? 1.2 : 1.8}
              >
                <img
                  src={paragraph.icon}
                  alt="glasses_icon"
                  style={{
                    ...styles.icon,
                    width: isMobile ? '2rem' : '2.5rem',
                  }}
                />
                <Typography
                  variant="h3"
                  color="primary"
                  fontSize={{ xs: '1.2rem', md: '1.5rem' }}
                >
                  {paragraph.title}
                </Typography>
              </Stack>
              <Typography variant="body1" maxWidth={paragraph.maxWidth}>
                {paragraph.text} {paragraph.mailTo}
              </Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>
      <Flippy
        style={{
          backgroundColor: 'transparent',
          width: '100%',
        }}
        flipOnClick={false}
        ref={cardRef}
      >
        <FrontSide
          style={{
            borderRadius: 18,
            padding: 0,
            boxShadow: 'none',
          }}
        >
          {realPhoto}
        </FrontSide>
        <BackSide
          style={{
            borderRadius: 18,
            padding: 0,
            boxShadow: 'none',
          }}
        >
          {secretPhoto}
        </BackSide>
      </Flippy>
      <SecretButton
        onClick={() => {
          flipCard();
        }}
      />{' '}
      {/* eslint-disable-line */}
    </Grid>
  );
}

export default Team;
