import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import zxcvbn from 'zxcvbn';
import { Typography } from '@mui/material';
import { getScoreProperties } from '../../../helpers/password';

function PasswordStrength({ password }) {
  const { score } = useMemo(() => zxcvbn(password), [password]);
  const { color, text } = getScoreProperties(score);
  const noPassword = password.length === 0;

  return (
    <Typography
      variant={noPassword ? 'caption' : 'body1'}
      color={noPassword ? 'text.secondary' : color}
      fontWeight="bold"
      textAlign="center"
      width="90%"
    >
      {noPassword
        ? 'Escribe tu contraseña para obtener una calificación de seguridad'
        : text}
    </Typography>
  );
}

PasswordStrength.propTypes = {
  password: PropTypes.string.isRequired,
};

export default PasswordStrength;
