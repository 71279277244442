import * as Yup from 'yup';

export const REGISTER_INITIAL_VALUES = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  password: '',
};

export const REGISTER_VALIDATION_SCHEMA = Yup.object({
  firstName: Yup.string().required('El nombre es obligatorio'),
  lastName: Yup.string().required('El apellido es obligatorio'),
  phoneNumber: Yup.string()
    .matches(/^\+?\d{9,}$/, 'Número de teléfono inválido')
    .required('El teléfono es obligatorio'),
  email: Yup.string()
    .email('Correo electrónico inválido')
    .required('El correo electrónico es obligatorio'),
  password: Yup.string()
    .required('La contraseña es obligatoria')
    .min(8, 'Se requieren mínimo 8 caracteres')
    .matches(/\d/, 'Se requiere al menos un número')
    .matches(/[a-zA-Z]/, 'Se requiere al menos una letra'),
});

export const PASSWORD_VALIDATORS = [
  {
    id: 'minLength',
    test: (val) => val.length >= 8,
    text: 'Se requieren mínimo 8 caracteres',
  },
  {
    id: 'letter',
    test: (val) => /[a-zA-Z]/.test(val),
    text: 'Se requiere al menos una letra',
  },
  {
    id: 'number',
    test: (val) => /\d/.test(val),
    text: 'Se requiere al menos un número',
  },
];
