import { gql } from '@apollo/client';

export const USER_INTERACTION_REGISTER = gql`
  mutation userInteractionRegister($action: UserInteractionInputType!) {
    userInteractionRegister(action: $action) {
      interaction {
        id
      }
    }
  }
`;

export const HELP_CENTER_EMAIL = gql`
  mutation HelpCenterMail(
    $name: String!
    $email: String!
    $phoneNumber: String!
    $message: String!
  ) {
    helpCenterMail(
      name: $name
      email: $email
      phoneNumber: $phoneNumber
      message: $message
    ) {
      success
    }
  }
`;

export const LOGIN = gql`
  mutation TokenAuth(
    $email: String!
    $password: String!
    $utmCampaign: String
  ) {
    tokenAuth(email: $email, password: $password, utmCampaign: $utmCampaign) {
      payload
      refreshExpiresIn
      token
      refreshToken
      user {
        id
      }
    }
  }
`;

export const REGISTER = gql`
  mutation RegisterUser(
    $email: String!
    $firstName: String
    $lastName: String
    $password: String!
    $phoneNumber: String
    $utmCampaign: String
    $origin: String
  ) {
    registerUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
      phoneNumber: $phoneNumber
      utmCampaign: $utmCampaign
      origin: $origin
    ) {
      response
    }
  }
`;

export const ACTIVATE_COLLECTION_PLAN = gql`
  mutation ActivateCollectionPlan($planId: ID!) {
    activateCollectionPlan(planId: $planId) {
      user {
        id
        permissions
        userPlanSubscriptions(isActive: true) {
          edges {
            node {
              id
              isActive
              isAdmin
            }
          }
        }
        selectedCompany {
          id
          masterEntity {
            id
            subscriptions(isActive: true) {
              edges {
                node {
                  id
                  isActive
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const REQUEST_COLLECTION_PLAN_DEMO = gql`
  mutation RequestCollectionPlanDemo(
    $name: String!
    $phoneNumber: String!
    $email: String!
  ) {
    requestCollectionPlanDemo(
      name: $name
      phoneNumber: $phoneNumber
      email: $email
    ) {
      success
    }
  }
`;

export const UPLOAD_CREDENTIALS = gql`
  mutation uploadCredentials(
    $username: String!
    $password: String!
    $companyId: Int
    $credentialProvider: Int!
    $source: String
    $origin: String
  ) {
    uploadCredentials(
      username: $username
      password: $password
      companyId: $companyId
      credentialProvider: $credentialProvider
      source: $source
      origin: $origin
    ) {
      getUser {
        id
        selectedCompany {
          id
        }
      }
    }
  }
`;
