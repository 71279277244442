import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { Button } from '@mui/material';
import useRegister from '../../../hooks/useRegister';

function RegisterButton({ onCompleted }) {
  // eslint-disable-next-line operator-linebreak
  const { values, isSubmitting, setTouched, validateForm, resetForm } =
    useFormikContext();
  const { register, loading } = useRegister(values, () => {
    onCompleted();
    resetForm();
  });

  const handleRegisterClick = async () => {
    const fieldsTouched = Object.keys(values).reduce((acc, key) => {
      acc[key] = true;
      return acc;
    }, {});
    setTouched(fieldsTouched);

    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      register();
    }
  };

  return (
    <Button
      id="complete-form-register-kobros"
      fullWidth
      variant="contained"
      color="secondary"
      disabled={isSubmitting || loading}
      onClick={handleRegisterClick}
      sx={{
        mb: 3,
        fontSize: { xs: 10, smratphone: 12, md: 14 },
        width: { xs: 150, smartphone: 200 },
        borderRadius: 20,
        height: { xs: 30, md: 41 },
      }}
    >
      Crear cuenta
    </Button>
  );
}

RegisterButton.propTypes = {
  onCompleted: PropTypes.func,
};

RegisterButton.defaultProps = {
  onCompleted: null,
};

export default RegisterButton;
