import React from 'react';
import ProductsKobrosSection from './ProductsKobrosSection';
import useGetCountry from '../../../helpers/useGetCountry';

function ProductSpecials() {
  const country = useGetCountry();
  const countryComponentMapper = {
    Chile: <ProductsKobrosSection />,
  };
  return country && countryComponentMapper[country];
}

export default ProductSpecials;
