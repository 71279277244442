import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import fingoPlatformImage from '../../assets/home/images/fingo_platform.png';
import { useIsFirstTimeVisible, useIsMobile } from '../../hooks';
import { Bold } from '../Utils/typography';

function OurPlatform() {
  const isMobile = useIsMobile();
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  useIsFirstTimeVisible({ ref, isVisible, setIsVisible });
  const navigate = useNavigate();
  return (
    <Stack
      ref={ref}
      direction={isMobile ? 'column' : 'row-reverse'}
      sx={{
        width: isMobile ? '90%' : '76%',
        mb: isMobile ? 10 : 30,
      }}
      alignItems="center"
      spacing={isMobile ? 5 : 2}
    >
      <Stack
        direction="column"
        spacing={isMobile ? 3 : 5}
        width={isMobile ? '100%' : '50%'}
        alignItems={isMobile ? 'center' : 'flex-start'}
        textAlign={isMobile ? 'center' : 'left'}
      >
        <Typography
          variant="h2"
          sx={{
            opacity: isVisible ? 1 : 0,
            transition: 'opacity 1s ease-in-out',
          }}
        >
          Nuestra <Box display="inline" color="secondary.main">plataforma</Box>
        </Typography>
        <Typography
          variant="subtitle2"
          fontSize={{ xs: '0.8rem', md: '1rem' }}
          width="90%"
          sx={{
            opacity: isVisible ? 1 : 0,
            transition: 'opacity 1s ease-in-out',
            transitionDelay: '0.2s',
          }}
        >
          <Bold>Automatiza</Bold> los procesos y opera de manera{' '}
          <Bold>más fácil, más segura y más eficiente</Bold>.<br />
          Moderniza tus operaciones financieras para que tu equipo pueda
          concentrarse en lo que más importa: <Bold>Hacer crecer y escalar su negocio</Bold>.<br />
          Revisa las funciones la plataforma Fingo en detalle.
        </Typography>
        <Button
          onClick={() => navigate('/platform')}
          variant="outlined"
          size="large"
          id="our-platform-more"
          sx={{
            opacity: isVisible ? 1 : 0,
            transition: 'opacity 1s ease-in-out',
            width: isMobile ? '100%' : '10rem',
            height: '2.5rem',
            color: isMobile ? 'white' : 'secondary.main',
            backgroundColor: isMobile ? 'secondary.main' : 'white',
            borderColor: 'secondary.main',
            borderRadius: isMobile ? '1.5rem' : '0.5rem',
            '&:hover': {
              backgroundColor: 'secondary.main',
              color: 'white',
              border: 'none',
            },
          }}
        >
          Ver más
        </Button>
      </Stack>
      <Box
        sx={{
          width: isMobile ? '100%' : '60%',
          opacity: isVisible ? 1 : 0,
          transition: 'opacity 1s ease-in-out',
          transitionDelay: '0.4s',
        }}
      >
        <img
          src={fingoPlatformImage}
          alt="product-factoring"
          style={{
            width: '100%',
            opacity: isVisible ? 1 : 0,
            transition: 'opacity 1s ease-in-out',
            transitionDelay: '0.4s',
          }}
        />
      </Box>
    </Stack>
  );
}

export default OurPlatform;
