import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import FingoLogoPng from '../../../assets/brand/images/logo_with_name.png';

function FingoLogo({ ...props }) {
  return (
    <Box
      component="img"
      src={FingoLogoPng}
      alt=""
      sx={{
        maxHeight: 80,
        maxWidth: 'auto',
        objectFit: 'contain',
        ...props.sx,
      }}
      {...props}
    />
  );
}

FingoLogo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object, // Add this line
};

FingoLogo.defaultProps = {
  sx: {},
};
export default FingoLogo;
