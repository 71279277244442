import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { factoringVideo } from '../../constants/help-center-links';
import getPlatformUrlByCountry from '../../helpers/getPlatformUrlByCountry';
import handleUtmRedirect from '../../helpers/handleUtmRedirect';
import { useIsFirstTimeVisible, useIsMobile } from '../../hooks';
import useGetCountry from '../../helpers/useGetCountry';

function HelpVideo() {
  const isMobile = useIsMobile();
  const ref = useRef(null);
  const country = useGetCountry();
  const [isVisible, setIsVisible] = useState(false);
  useIsFirstTimeVisible({ ref, isVisible, setIsVisible });
  const platformUrl = getPlatformUrlByCountry();
  const handleOnClick = () => {
    handleUtmRedirect(`${platformUrl}/register`);
  };
  return (
    <Stack
      direction="column"
      alignItems="center"
      spacing={2}
      sx={{
        mb: 20,
        px: 2,
      }}
    >
      <Typography
        variant="h2"
        color="text.primary"
        sx={{
          opacity: isVisible ? 1 : 0,
          transition: 'opacity 1s ease-in-out',
        }}
      >
        ¿Cómo evaluar factoring?
      </Typography>
      <Typography
        variant="subtitle2"
        color="text.primary"
        sx={{
          fontSize: { xs: '0.9rem', md: '1.2rem' },
          opacity: isVisible ? 1 : 0,
          transition: 'opacity 1s ease-in-out',
          transitionDelay: '0.2s',
          textAlign: 'center',
        }}
      >
        En este video podrás ver lo simple que es evaluar tus facturas con Fingo
      </Typography>
      <Box
        ref={ref}
        sx={{
          opacity: isVisible ? 1 : 0,
          transition: 'opacity 1s ease-in-out',
          transitionDelay: '0.6s',
          paddingBottom: 1.5,
        }}
      >
        <ReactPlayer
          url={factoringVideo[country]}
          width={isMobile ? '90vw' : '60vw'}
          height={isMobile ? '30vh' : '60vh'}
        />
      </Box>
      <Button
        id="landing-help-video"
        onClick={handleOnClick}
        variant="contained"
        sx={{
          opacity: isVisible ? 1 : 0,
          transition: 'opacity 1s ease-in-out',
          transitionDelay: '0.8s',
          height: '2.5rem',
          width: isMobile ? '100%' : '15rem',
          borderRadius: isMobile ? '1.5rem' : '0.5rem',
        }}
      >
        Evalúa tus facturas hoy
      </Button>
    </Stack>
  );
}

export default HelpVideo;
