import React from 'react';
import useGetCountry from '../../helpers/useGetCountry';
import KobrosSection from './KobrosSection';

function ProductSection() {
  const country = useGetCountry();
  const countryComponentMapper = {
    Chile: <KobrosSection />,
  };
  return country && countryComponentMapper[country];
}

export default ProductSection;
