import useGetCountry from './useGetCountry';

const getPlatformUrlByCountry = () => {
  const country = useGetCountry();
  if (country === 'Chile') {
    return 'https://app.fingo.cl';
  }
  if (country === 'Mexico') {
    return 'https://app.fingo.com.mx';
  }
  return 'https://app.fingo.cl';
};

export default getPlatformUrlByCountry;
