import React from 'react';
import { getCdnUrl } from '../../helpers';
import { ProductIntro } from '../Utils/headers';

const name = 'Nuestros productos financieros';

function OurProductsHeader() {
  return (
    <ProductIntro
      name={name}
      image={getCdnUrl('/images/pc_products.png')}
    />
  );
}

export default OurProductsHeader;
