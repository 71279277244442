import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Buro } from '../../../assets/home';
import { useIsMobile } from '../../../hooks';

function BuroLogo() {
  const buroName = 'Información del Buró de Entidades Financieras';
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      maxWidth={isMobile ? '35%' : 220}
      alignItems="center"
      spacing={1}
    >
      <Box
        component="img"
        src={Buro}
        alt=""
        sx={{
          maxHeight: 80,
          maxWidth: 'auto',
          objectFit: 'contain',
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {
            transform: 'scale(1.05)',
            cursor: 'pointer',
          },
        }}
        onClick={() => {
          navigate('/help/buro-info');
        }}
      />
      <Typography variant="subtitle4" justifyContent="center" textAlign={isMobile ? 'center' : 'left'}>
        {buroName}
      </Typography>
    </Stack>
  );
}

export default BuroLogo;
