import React from 'react';
import { Chip, Stack } from '@mui/material';
import { FAQ_ITEMS } from '../../constants/kobros';
import KobrosAccordionItem from '../Utils/accordions/KobrosAccordionItem';
import CompositeTypography from '../Utils/typography/CompositeTypography';

const FAQ_TEXT = [
  { id: 1, text: 'Preguntas', color: 'text.main', fontSize: 90 },
  { id: 2, text: ' frecuentes', color: 'secondary.main' },

];

function FAQ() {
  return (
    <Stack
      id="home-faq"
      direction="column"
      alignItems="center"
      minHeight="80vh"
      spacing={5}
      p={{ xs: 2, md: 5 }}
    >
      <CompositeTypography
        textParts={FAQ_TEXT}
        mt={8}
      />
      <Chip
        sx={{
          my: 2,
          left: '45%',
          width: '10%',
          height: '12px',
          color: 'background.light',
        }}
      />
      <Stack width={{ xs: '100%', md: '75vw' }} maxWidth="100%" flex={2}>
        {FAQ_ITEMS.map((question) => (KobrosAccordionItem(question)))}
      </Stack>
    </Stack>
  );
}

export default FAQ;
